






























import Vue from "vue";
import StatusToggle from "../../StatusToggle.vue";
import { mapMutations, mapState, mapGetters } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState(["employee"]),
    ...mapGetters("issue", ["isEditing"]),
    ...mapState("issue", ["status", "id"]),
    ...mapState("basicCase", ["description"])
  },

  methods: {
    ...mapMutations("issue", ["toggleStatus"])
  },

  components: {
    StatusToggle
  }
});
