import Bugsnag, { BrowserConfig, Client } from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import Vue from "vue";

export abstract class BugReporter {
  public abstract notify(error: Error): void;
}

export class BugsnagReporter extends BugReporter {
  protected client: Client;

  constructor(apiKey: string, appVersion: string = "develop") {
    super();

    this.client = Bugsnag.start({
      apiKey,
      appVersion,
      collectUserIp: false,
      filters: [/^password/i, /_token$/, /_key$/],
      plugins: [new BugsnagPluginVue(Vue as any)]
    } as BrowserConfig);
  }

  public notify(error: Error): void {
    this.client.notify(error);
  }
}

export class ConsoleReporter extends BugReporter {
  public notify(error: Error): void {
    console.error(error);
  }
}
