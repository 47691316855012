

























import Vue from "vue";
import { SelectOption, Role } from "../../../types";
import { mapState, mapActions } from "vuex";
import { FILTER_ROLE } from "../../../constants";

export default Vue.extend({
  data() {
    return {
      selected: []
    };
  },

  computed: {
    ...mapState(["roles"]),

    choices(): SelectOption[] {
      return this.roles.map(
        (role: Role): SelectOption => ({
          display: role.title,
          value: role.id
        })
      );
    }
  },
  methods: {
    ...mapActions("listing", ["addFilter"]),
    add() {
      this.addFilter({
        type: FILTER_ROLE,
        choices: this.selected.map((c: SelectOption) => c.value)
      });
    }
  }
});
