













import Vue from "vue";

export default Vue.extend({
  props: {
    type: {
      required: false,
      type: String,
      default: (): string => "info"
    },
    dismissable: {
      type: Boolean,
      required: false,
      default: (): boolean => true
    }
  },

  data() {
    return {
      open: true
    };
  },

  methods: {
    close() {
      this.open = false;
    }
  }
});
