











import Vue from "vue";
import StatusFilter from "../CommonFilters/Applied/StatusFilter.vue";
import StoreFilter from "../CommonFilters/Applied/StoreFilter.vue";
import ReasonCodeFilter from "../CommonFilters/Applied/ReasonCodeFilter.vue";
import CaseTypeFilter from "./AppliedFilters/CaseTypeFilter.vue";
import { Filter } from "../../types";

export default Vue.extend({
  props: {
    filters: {
      required: true,
      type: Array as () => Filter[]
    }
  },

  components: {
    StatusFilter,
    StoreFilter,
    ReasonCodeFilter,
    CaseTypeFilter
  }
});
