










































































import Vue from "vue";
import AppliedFilters from "./AppliedFilters.vue";
import RecordListing from "../RecordListing/RecordListing.vue";
import AddFilter from "./AddFilter.vue";
import { mapActions, mapState } from "vuex";
import { QueryParams, Employee } from "../../types";
import {
  FILTER_STATUS,
  FILTER_STORE,
  FILTER_REASON_CODE,
  FILTER_CASE_TYPE
} from "../../constants";
import { getFilterParam } from "../../utils";

export default Vue.extend({
  props: {
    queryParams: {
      type: Object,
      required: false,
      default: (): QueryParams => ({})
    }
  },

  created() {
    this.loadFilterDependencies();
  },

  computed: {
    ...mapState("listing", ["filters"]),

    params(): QueryParams {
      return {
        ...this.queryParams,
        ...getFilterParam(this.filters, FILTER_STATUS, "status"),
        ...getFilterParam(this.filters, FILTER_REASON_CODE, "reason_code"),
        ...getFilterParam(this.filters, FILTER_STORE, "stores"),
        ...getFilterParam(this.filters, FILTER_CASE_TYPE, "type")
      };
    }
  },

  methods: {
    ...mapActions(["loadStores"]),
    ...mapActions("callOut", ["loadReasonCodes"]),

    fullName(employee: Employee): string {
      return `${employee.first_name} ${employee.last_name}`;
    },

    loadFilterDependencies(): Promise<void[]> {
      return Promise.all([this.loadStores(), this.loadReasonCodes()]);
    }
  },

  components: {
    RecordListing,
    AppliedFilters,
    AddFilter
  }
});
