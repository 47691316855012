import axios from "axios";
import { RootState } from "../types";

import { ActionTree } from "vuex";

export default {
  async loadStores({ commit }) {
    try {
      const {
        data: { data: stores }
      } = await axios.get("/api/stores");

      commit("setStores", stores);
    } catch (error) {
      commit(
        "listing/setError",
        "An unexpected error occurred. Please try again later.",
        { root: true }
      );
    }
  },

  async loadEmployee({ commit }, id) {
    const {
      data: { data: employee }
    } = await axios.get(`/api/employees/${id}`);

    commit("setEmployee", employee);
  },

  async loadDepartments({ commit }) {
    try {
      const {
        data: { data: departments }
      } = await axios.get("/api/departments");

      commit("setDepartments", departments);
    } catch (error) {
      commit(
        "listing/setError",
        "An unexpected error occurred. Please try again later.",
        { root: true }
      );
    }
  },

  async loadGroups({ commit }) {
    try {
      const {
        data: { data: groups }
      } = await axios.get("/api/employee-groups");

      commit("setGroups", groups);
    } catch (error) {
      commit(
        "listing/setError",
        "An unexpected error occurred. Please try again later.",
        { root: true }
      );
    }
  },

  async loadRoles({ commit }) {
    try {
      const {
        data: { data: groups }
      } = await axios.get("/api/roles");

      commit("setRoles", groups);
    } catch (error) {
      commit(
        "listing/setError",
        "An unexpected error occurred. Please try again later.",
        { root: true }
      );
    }
  }
} as ActionTree<RootState, RootState>;
