









import Vue from "vue";
import { isNil } from "lodash";

export default Vue.extend({
  props: {
    value: {
      required: false
    },
    fallback: {
      type: String,
      required: false,
      default: (): string => "Not Available"
    }
  },

  computed: {
    usingFallback() {
      return isNil(this.value) || String(this.value).trim().length <= 0;
    }
  }
});
