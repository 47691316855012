import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCaretDown,
  faCaretRight,
  faDownload,
  faSyncAlt,
  faTimes,
  faToggleOff,
  faToggleOn
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue from "vue";
import Multiselect from "vue-multiselect";

import DescriptiveBlock from "./components/DescriptiveBlock.vue";
import FormattedTimestamp from "./components/FormattedTimestamp.vue";
import ListingPaginator from "./components/ListingPaginator.vue";
import LoadingIndicator from "./components/LoadingIndicator.vue";
import MessageContent from "./components/MessageContent.vue";
import MessageTypeTag from "./components/MessageTypeTag.vue";
import RelativeTimestamp from "./components/RelativeTimestamp.vue";
import SendReceiveStats from "./components/SendReceiveStats.vue";
import SortToggle from "./components/SortToggle.vue";
import StatusMessage from "./components/StatusMessage.vue";
import StatusWell from "./components/StatusWell.vue";
import TagLabel from "./components/TagLabel.vue";
import TagRole from "./components/TagRole.vue";
import TagStatus from "./components/TagStatus.vue";
import TruncatedCode from "./components/TruncatedCode.vue";
import ValueWithFallback from "./components/ValueWithFallback.vue";
import container from "./services";

// resolve the bugsnag service to get it hooked up right away
container.resolve("bugreporter");

container.resolve("bus");

// inject the authenticated user's timezone into all vue components
Vue.prototype.$timezone = window.VILLAGE.timezone;

// configure font-awesome for Vue.js
library.add(
  faDownload,
  faTimes,
  faSyncAlt,
  faCaretDown,
  faCaretRight,
  faToggleOn,
  faToggleOff
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("relative-timestamp", RelativeTimestamp);
Vue.component("formatted-timestamp", FormattedTimestamp);
Vue.component("message-type-tag", MessageTypeTag);
Vue.component("truncated-code", TruncatedCode);
Vue.component("send-receive-stats", SendReceiveStats);
Vue.component("value-with-fallback", ValueWithFallback);
Vue.component("status-message", StatusMessage);
Vue.component("loading-indicator", LoadingIndicator);
Vue.component("multiselect", Multiselect);
Vue.component("listing-paginator", ListingPaginator);
Vue.component("tag-label", TagLabel);
Vue.component("tag-role", TagRole);
Vue.component("tag-status", TagStatus);
Vue.component("sort-toggle", SortToggle);
Vue.component("message-content", MessageContent);
Vue.component("descriptive-block", DescriptiveBlock);
Vue.component("status-well", StatusWell);
// Disable production tips in console
Vue.config.productionTip = false;
