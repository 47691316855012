import { MutationTree } from "vuex";
import { IssueState, Issue } from "resources/assets/types";

export default {
  setIssue(state, issue: Issue) {
    state.status = issue.status;
    state.id = issue.id;
    state.hasGivenVCCSEnrollmentReminder = issue.given_vccs_enrollment_reminder;
  },

  toggleStatus(state) {
    state.status = state.status === "open" ? "closed" : "open";
  },

  setHasGivenVCCSEnrollmentReminder(
    state,
    hasGivenVCCSEnrollmentReminder: boolean
  ) {
    state.hasGivenVCCSEnrollmentReminder = hasGivenVCCSEnrollmentReminder;
  }
} as MutationTree<IssueState>;
