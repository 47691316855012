import Vue from "vue";
import RoleSelect from "../../components/RoleSelect.vue";
import { Role } from "resources/assets/types";

export const PageComponent = Vue.extend({
  props: {
    initialRoles: {
      required: false,
      type: Array as () => number[],
      default: []
    }
  },
  data() {
    return {
      roles: this.initialRoles
    };
  },
  methods: {
    updateRoles(roles: Role[]) {
      this.roles = roles.map((r: Role) => r.id);
    }
  },
  components: {
    RoleSelect
  }
  //@ts-ignore
}).extendOptions;
