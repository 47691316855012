import { Resolver, ServiceFactory } from "./types";

/**
 * A service factory that recalls the resolver function every the underlying
 * service is requested.
 */
export default class ServiceInstance<T> implements ServiceFactory<T> {
  constructor(public identifier: string, protected resolver: Resolver<T>) {
    //
  }

  public resolve(): T {
    return this.resolver();
  }
}
