





















import Vue from "vue";
import { isNil } from "lodash";

export default Vue.extend({
  props: {
    tag: {
      type: String,
      required: false
    },
    href: {
      type: String,
      required: false
    },
    variant: {
      type: String,
      required: false,
      default: (): string => "regular"
    }
  },
  computed: {
    tagName(): string {
      return !isNil(this.href) ? "a" : "div";
    }
  }
});
