import ServiceInstance from "./ServiceInstance";

/**
 * A memoized version of ServiceInstance; caches the resolved service the first
 * time it's requested from the factory, returning that same value every time.
 */
export default class ServiceSingleton<T> extends ServiceInstance<T> {
  protected resolved: T | null = null;

  public resolve(): T {
    if (this.resolved) {
      return this.resolved;
    }

    return (this.resolved = super.resolve());
  }
}
