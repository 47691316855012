
































































































































import { Employee, TaskType } from "../../../types";
import Vue from "vue";
import DefaultLayout from "./DefaultLayout.vue";
import { DateTime } from "luxon";
import {
  TASK_PDFRM_AMT,
  TASK_KRONOS_UPDATE,
  TASK_FOLLOW_UP
} from "../../../constants";

export default Vue.extend({
  extends: DefaultLayout,

  data() {
    return {
      TASK_FOLLOW_UP,
      TASK_KRONOS_UPDATE,
      TASK_PDFRM_AMT
    };
  },

  methods: {
    fullName(employee: Employee): string {
      return `${employee.first_name} ${employee.last_name}`;
    },

    daysSince(dateString: string): string {
      const diff = DateTime.local().diff(DateTime.fromISO(dateString), "days");
      const days = diff.toObject().days;
      const approxDays = Math.floor(days || 0);

      return Intl.NumberFormat("en-US").format(approxDays);
    },

    isType(type: TaskType): boolean {
      // @ts-ignore-next-line
      return this.queryParams.type === type;
    }
  }
});
