import Vue from "vue";

import store from "./store";
import { PageModule, PageModules } from "./types";
import { $ } from "./utils";

/**
 * Load all page modules via webpack require.context
 *
 * @link https://webpack.js.org/guides/dependency-management#requirecontext
 */
const pageModules: PageModules = {};

// @ts-ignore
const importAll = r =>
  (r.keys() as string[]).forEach(
    key =>
      (pageModules[
        key
          .replace(/^\.\//, "")
          .replace(/\.(ts|js)/, "")
          .replace(/page_/, "")
      ] = r(key))
  );

importAll(require.context("./pages", true, /page_.+\.(ts|js)$/));

/**
 * Resolve the page module to use for the current request by looking for a [data-js-module]
 * attribute on a tag matching the passed selector.
 *
 * @param selector string  The selector to look for the [data-js-module] on
 * @return PageModule
 */
const getPageModule = (selector: string): PageModule => {
  const module = ($(selector) || new HTMLElement()).dataset.jsModule;

  if (module) {
    if (Object.prototype.hasOwnProperty.call(pageModules, module)) {
      console.info(`Using [${module}] based on [data-js-module] value`);
      return pageModules[module];
    }

    throw new Error(
      `There is no [${module}] module defined in the list of page modules.`
    );
  }

  console.info(
    `No [data-js-module] was set for this page on the first tag matching selector: \`${selector}\`. Using [fallback] module instead.`
  );

  return pageModules["fallback"];
};

export const bootPage = () => {
  const page = getPageModule("body");

  if (page.bootstrap) {
    page.bootstrap.call(undefined);
  }

  // Load and mount the page component
  new Vue({
    store,
    ...page.PageComponent,
    propsData: window.VILLAGE.propsData
  }).$mount("#app");
};
