import axios from "axios";
import { isNil } from "lodash";
import { CallOut, CallOutState, RootState } from "resources/assets/types";
import { ActionTree } from "vuex";

export default {
  async loadReasonCodes({ commit }) {
    const { data: reasonCodes } = await axios.get("/api/call-out-reason-codes");

    commit("setReasonCodes", reasonCodes?.data || []);
  },

  setCallOut({ commit }, callOut: CallOut) {
    commit("setCreatedAt", callOut.created_at);
    commit("setReason", callOut.reason);
    commit("setReasonCodeID", callOut.reason_code.id);
    commit("setDateRange", { from: callOut.starts_at, to: callOut.ends_at });

    if (!isNil(callOut.questions)) {
      commit("setQuestions", callOut.questions);
    }

    if (!isNil(callOut.requires_sick_pay)) {
      commit("setRequiresSickPay", callOut.requires_sick_pay);
    }

    if (!isNil(callOut.requires_pto_pay)) {
      commit("setRequiresPtoPay", callOut.requires_pto_pay);
    }

    if (!isNil(callOut.covid_tested)) {
      commit("setCovidTested", callOut.covid_tested);
    }

    if (!isNil(callOut.covid_test_results)) {
      commit("setCovidTestResults", callOut.covid_test_results);
    }

    if (!isNil(callOut.covid_contact)) {
      commit("setCovidContact", callOut.covid_contact);
    }

    if (!isNil(callOut.covid_symptoms)) {
      commit("setCovidSymptoms", callOut.covid_symptoms);
    }

    if (!isNil(callOut.covid_exposed_on)) {
      commit("setCovidExposedOn", callOut.covid_exposed_on);
    }

    if (!isNil(callOut.covid_exposure_was_work_related)) {
      commit(
        "setCovidExposureWasWorkRelated",
        callOut.covid_exposure_was_work_related
      );
    }

    if (!isNil(callOut.is_immunocompromised)) {
      commit("setIsImmunocompromised", callOut.is_immunocompromised);
    }

    if (!isNil(callOut.has_doctors_note)) {
      commit("setHasDoctorsNote", callOut.has_doctors_note);
    }

    if (!isNil(callOut.cares_for_high_risk_person)) {
      commit("setCaresForHighRiskPerson", callOut.cares_for_high_risk_person);
    }
  }
} as ActionTree<CallOutState, RootState>;
