


















import Vue from "vue";
import { mapActions } from "vuex";
import { DateTime } from "luxon";
import { FILTER_DUE_ON } from "../../../constants";
import { hasValue } from "../../../utils";
import { OptionalString } from "../../../types";

export default Vue.extend({
  data() {
    return {
      dueOn: undefined as OptionalString,
      considerDepartment: 0
    };
  },

  computed: {
    isValid(): boolean {
      if (!hasValue(this.dueOn)) {
        return false;
      }

      if (!DateTime.fromSQL(this.dueOn as string)) {
        return false;
      }

      return true;
    }
  },
  methods: {
    ...mapActions("listing", ["addFilter"]),

    add() {
      this.addFilter({
        type: FILTER_DUE_ON,
        value: this.dueOn
      });
    }
  }
});
