

































































import Vue from "vue";
import AppliedFilters from "./AppliedFilters.vue";
import RecordListing from "../RecordListing/RecordListing.vue";
import AddFilter from "./AddFilter.vue";
import { mapActions, mapGetters } from "vuex";
import { QueryParams } from "../../types";
import { merge } from "lodash";

export default Vue.extend({
  props: {
    endpoint: {
      type: String,
      required: false,
      default: (): string => "/api/employees"
    },
    exportEndpoint: {
      type: String,
      required: false
    },
    queryParams: {
      type: Object,
      required: false,
      default: (): QueryParams => ({})
    },
    filterable: {
      type: Boolean,
      required: false,
      default: false
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  created() {
    this.loadFilterDependencies();
  },

  computed: {
    ...mapGetters("employees", ["additionalQueryParams"]),

    params(): QueryParams {
      return merge({}, this.queryParams, this.additionalQueryParams);
    }
  },

  methods: {
    ...mapActions("employees", ["loadQuestions"]),
    ...mapActions(["loadStores", "loadDepartments", "loadGroups"]),

    loadFilterDependencies(): Promise<void[]> {
      return Promise.all([
        this.loadQuestions(),
        this.loadStores(),
        this.loadDepartments(),
        this.loadGroups()
      ]);
    }
  },

  components: {
    RecordListing,
    AppliedFilters,
    AddFilter
  }
});
