



















































































import axios from "axios";
import Vue from "vue";
import qs from "qs";
import ListingSearch from "./ListingSearch.vue";
import ListingSelection from "./ListingSelection.vue";
import { Employee, QueryParams } from "../../types";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import pluralize from "pluralize";
import { merge, isNil } from "lodash";

export default Vue.extend({
  props: {
    endpoint: {
      type: String,
      required: true
    },
    exportEndpoint: {
      type: String,
      required: false
    },
    queryParams: {
      type: Object,
      required: false,
      default: (): QueryParams => ({})
    },
    searchable: {
      type: Boolean,
      required: false,
      default: true
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false
    },
    kind: {
      type: String,
      required: false,
      default: (): string => "record"
    },
    selectableField: {
      type: String,
      required: false,
      default: (): string => "selected"
    }
  },

  watch: {
    search: "refresh",
    page: "refresh",
    filters: "refresh"
  },

  created() {
    this.refresh();
  },

  computed: {
    ...mapState("listing", [
      "selectingAll",
      "error",
      "page",
      "pagination",
      "filters",
      "records",
      "loading",
      "search"
    ]),
    ...mapGetters("listing", ["isSelected"]),

    hasExportURL(): boolean {
      return !isNil(this.exportEndpoint);
    },

    exportURL(): string | undefined {
      if (!this.hasExportURL) {
        return undefined;
      }

      return axios.getUri({
        url: this.exportEndpoint,
        params: this.queryParams,
        paramsSerializer: params => qs.stringify(params, { encode: false })
      });
    }
  },

  methods: {
    ...mapMutations("listing", ["toggleSelection"]),
    ...mapActions("listing", ["loadRecords"]),
    pluralize,

    refresh() {
      if (this.loading) {
        return;
      }

      this.loadRecords({
        endpoint: this.endpoint,
        queryParams: merge({}, this.queryParams)
      });
    }
  },

  components: {
    ListingSelection,
    ListingSearch
  }
});
