





































import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  props: {
    initiallyActive: {
      required: true,
      type: Boolean
    },
    what: {
      required: true,
      type: String
    },
    endpoint: {
      required: true,
      type: String
    },
    attribute: {
      required: false,
      type: String,
      default: (): string => "is_active"
    }
  },

  data() {
    return {
      active: this.initiallyActive
    };
  },

  computed: {
    currentState(): string {
      return this.active ? "active" : "inactive";
    },

    nextState(): string {
      return this.active ? "inactive" : "active";
    },

    stateIcon(): string {
      return this.active ? "toggle-on" : "toggle-off";
    },

    stateColor(): string {
      return this.active ? "teal" : "red";
    }
  },

  methods: {
    async toggle() {
      this.active = !this.active;

      try {
        const {
          data: { is_active: isActive }
        } = await axios.put(this.endpoint, { [this.attribute]: this.active });

        this.active = isActive;
      } catch {
        this.active = !this.active;
      }
    }
  }
});
