










import Vue from "vue";
import { Message, Response, Filter, SelectOption } from "../../types";
import { mapActions } from "vuex";
import { isNil } from "lodash";

export default Vue.extend({
  props: {
    cid: {
      required: true,
      type: String
    }
  },

  methods: {
    ...mapActions("listing", ["removeFilter"])
  }
});
