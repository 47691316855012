




































































import Vue from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";
import {
  COVID_RESULTS_POSITIVE,
  CALLOUT_REASON_SICK
} from "../../../constants";
import { OptionalString } from "../../../types";
import { hasValue } from "../../../utils";
import { DateTime } from "luxon";
import { isNil } from "lodash";

/**
 * If the employee indicated they tested positive for COVID-19, more information
 * needs to be collected in order to notify employees working nearby at the time.
 */
export default Vue.extend({
  data() {
    return {
      followUpOn: undefined as OptionalString
    };
  },

  computed: {
    ...mapState("callOut", [
      "reason",
      "covidTested",
      "covidContact",
      "covidTestResults",
      "covidExposureWasWorkRelated",
      "covidExposedOn"
    ]),
    ...mapGetters("issue", ["isEditing"]),

    today(): DateTime {
      return DateTime.local();
    },

    exposed(): string {
      if (isNil(this.covidExposedOn)) {
        return "";
      }

      return DateTime.fromISO(this.covidExposedOn, { zone: "utc" }).toFormat(
        "y-LL-dd"
      );
    },

    /**
     * This form should be visible when the employee indicated that they tested
     * positive for COVID-19
     */
    isVisible(): boolean {
      if (![CALLOUT_REASON_SICK].includes(this.reason)) {
        return false;
      }

      return (
        (!this.covidTested && this.covidContact) ||
        (this.covidTested && this.covidTestResults === COVID_RESULTS_POSITIVE)
      );
    },

    canSetFollowUpDate(): boolean {
      return this.covidExposureWasWorkRelated && !this.isEditing;
    },

    exposedError(): boolean {
      return (
        hasValue(this.exposed) &&
        DateTime.fromSQL(this.exposed).startOf("day") >
          DateTime.local().startOf("day")
      );
    },

    followUpOnError(): boolean {
      return (
        hasValue(this.followUpOn) &&
        DateTime.fromSQL((this.followUpOn as unknown) as string).startOf(
          "day"
        ) <= DateTime.local().startOf("day")
      );
    }
  },

  methods: {
    ...mapMutations("callOut", [
      "setCovidExposedOn",
      "setFollowUpOn",
      "setCovidExposureWasWorkRelated"
    ]),

    updateExposed(event: InputEvent) {
      this.setCovidExposedOn((event.target as HTMLInputElement).value);
    },

    updateFollowUpOn(event: InputEvent) {
      this.followUpOn = (event.target as HTMLInputElement).value;
    }
  }
});
