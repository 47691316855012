




import Vue from "vue";
import { Role } from "../types";
import * as c from "../constants";

export default Vue.extend({
  props: {
    role: {
      required: true,
      type: Object as () => Role
    }
  },

  computed: {
    bgColorClass(): string {
      switch (this.role.name.toLowerCase()) {
        case c.ROLE_HR:
          return "bg-pink-500";
        case c.ROLE_PAYROLL:
          return "bg-teal-500";
        case c.ROLE_BENEFITS:
          return "bg-orange-500";
        case c.ROLE_SCHEDULING:
          return "bg-indigo-500";
        case c.ROLE_CALL_CENTER:
          return "bg-purple-500";
        default:
          return "bg-gray-700";
      }
    }
  }
});
