











import Vue from "vue";
import DepartmentFilter from "./AppliedFilters/DepartmentFilter.vue";
import StoreFilter from "../CommonFilters/Applied/StoreFilter.vue";
import ResponseFilter from "./AppliedFilters/ResponseFilter.vue";
import CloseContactFilter from "./AppliedFilters/CloseContactFilter.vue";
import ScheduledFilter from "./AppliedFilters/ScheduledFilter.vue";
import GroupFilter from "./AppliedFilters/GroupFilter.vue";
import { Filter } from "../../types";

export default Vue.extend({
  props: {
    filters: {
      required: true,
      type: Array as () => Filter[]
    }
  },

  components: {
    DepartmentFilter,
    StoreFilter,
    ResponseFilter,
    GroupFilter,
    CloseContactFilter,
    ScheduledFilter
  }
});
