












































import Vue from "vue";
import { mapActions } from "vuex";
import { DateTime } from "luxon";
import { FILTER_CLOSE_CONTACT } from "../../../constants";
import { hasValue } from "../../../utils";
import { OptionalString } from "../../../types";

export default Vue.extend({
  data() {
    return {
      employeeId: undefined as OptionalString,
      startDate: undefined as OptionalString,
      endDate: undefined as OptionalString,
      considerDepartment: 0
    };
  },

  computed: {
    isValid(): boolean {
      if (
        !hasValue(this.employeeId) ||
        !hasValue(this.startDate) ||
        !hasValue(this.endDate)
      ) {
        return false;
      }

      if (
        DateTime.fromSQL(this.startDate as string) >
        DateTime.fromSQL(this.endDate as string)
      ) {
        return false;
      }

      return true;
    }
  },
  methods: {
    ...mapActions("listing", ["addFilter"]),

    add() {
      this.addFilter({
        type: FILTER_CLOSE_CONTACT,
        value: {
          employee_id: this.employeeId,
          start_date: this.startDate,
          end_date: this.endDate,
          consider_department: Number(this.considerDepartment)
        }
      });
    }
  }
});
