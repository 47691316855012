import { CallOutReasonCode, CallOutReason } from "./types";

export const MATCH_EXACT: "exact" = "exact";
export const MATCH_CONTAINS: "contains" = "contains";

export const MESSAGE_PROFILE = "profile";
export const MESSAGE_NOTICE = "notice";
export const MESSAGE_QUESTION = "question";

// employee filtering
export const FILTER_RESPONSE: "response" = "response";
export const FILTER_STORE: "store" = "store";
export const FILTER_DEPARTMENT: "department" = "department";
export const FILTER_GROUP: "group" = "group";
export const FILTER_SCHEDULED: "scheduled" = "scheduled";
export const FILTER_CLOSE_CONTACT: "close-contact" = "close-contact";

// task & issue filtering
export const FILTER_STATUS: "status" = "status";
export const FILTER_ROLE: "role" = "role";
export const FILTER_REASON_CODE: "reason-code" = "reason-code";
export const FILTER_DUE_ON: "due-on" = "due-on";
export const FILTER_CASE_TYPE: "case-type" = "case-type";

export const NO_RESPONSE = "(((no response)))";

export const CALLOUT_REASON_CHILD_CARE = "child-care";
export const CALLOUT_REASON_TRANSPORTATION = "transportation";
export const CALLOUT_REASON_SCHEDULE_ISSUE = "schedule-issue";
export const CALLOUT_REASON_ACCOMMODATION = "accommodation";
export const CALLOUT_REASON_SICK = "sick";
export const CALLOUT_REASON_RESIGNED = "resigned";
export const CALLOUT_REASON_SELF_QUARANTINE = "self-quarantine";
export const CALLOUT_REASON_FORCED_QUARANTINE = "forced-quarantine";
export const CALLOUT_REASON_DIRECT_CONTACT = "direct-contact";
export const CALLOUT_REASON_COVID_POSITIVE = "covid-positive";
export const CALLOUT_REASON_OTHER = "other";

export const CALLOUT_REASONS: CallOutReason[] = [
  { type: CALLOUT_REASON_CHILD_CARE, display: "Child Care" },
  { type: CALLOUT_REASON_TRANSPORTATION, display: "Transportation" },
  { type: CALLOUT_REASON_SCHEDULE_ISSUE, display: "Schedule Issue" },
  { type: CALLOUT_REASON_ACCOMMODATION, display: "Accommodation" },
  { type: CALLOUT_REASON_SICK, display: "Sick" },
  { type: CALLOUT_REASON_RESIGNED, display: "Resigned" },
  { type: CALLOUT_REASON_SELF_QUARANTINE, display: "Self Quarantine" },
  { type: CALLOUT_REASON_FORCED_QUARANTINE, display: "Forced Quarantine" },
  { type: CALLOUT_REASON_DIRECT_CONTACT, display: "Direct Contact" },
  { type: CALLOUT_REASON_COVID_POSITIVE, display: "COVID Positive" },
  { type: CALLOUT_REASON_OTHER, display: "Other" }
];

export const COVID_RESULTS_NEGATIVE: 0 = 0;
export const COVID_RESULTS_POSITIVE: 1 = 1;
export const COVID_RESULTS_PENDING: 2 = 2;

export const STATUS_OPEN: "open" = "open";
export const STATUS_CLOSED: "closed" = "closed";
export const STATUS_TODO: "to do" = "to do";
export const STATUS_COMPLETE: "complete" = "complete";

export const ROLE_HR: "hr" = "hr";
export const ROLE_PAYROLL: "payroll" = "payroll";
export const ROLE_BENEFITS: "benefits" = "benefits";
export const ROLE_CALL_CENTER: "call-center" = "call-center";
export const ROLE_SCHEDULING: "scheduling" = "scheduling";

export const DEBOUNCE_DELAY = 350;

export const TASK_PDFRM_AMT: "pdfrm-amt" = "pdfrm-amt";
export const TASK_KRONOS_UPDATE: "kronos-update" = "kronos-update";
export const TASK_FOLLOW_UP: "follow-up" = "follow-up";

export const TASK_TYPES = [TASK_PDFRM_AMT, TASK_KRONOS_UPDATE, TASK_FOLLOW_UP];

export const ISSUE_CALL_OUT = "call-out";
export const ISSUE_BASIC = "basic";
