import CallOutForm from "../../../components/Issues/CallOut/CallOutForm.vue";
import Vue from "vue";
import { mapActions } from "vuex";
import RoleSelect from "../../../components/RoleSelect.vue";
import { isNil, isString } from "lodash";
import { Role } from "resources/assets/types";
import { DateTime } from "luxon";

const DATE_FORMAT = "y-LL-dd";

export const PageComponent = Vue.extend({
  props: {
    issueId: {
      required: false,
      type: Number
    }
  },

  created() {
    this.loadIssue(this.issueId);
  },

  data() {
    return {
      task: {
        role: null as number | null,
        description: null as string | null,
        dueOn: DateTime.local()
          .startOf("day")
          .plus({ days: 1 })
          .toFormat(DATE_FORMAT)
      }
    };
  },

  computed: {
    isTaskValid(): boolean {
      return (
        !isNil(this.task.role) &&
        isString(this.task.description) &&
        (this.task.description as string).length > 0
      );
    },
    minimumDate() {
      return DateTime.local()
        .startOf("day")
        .toFormat(DATE_FORMAT);
    }
  },

  methods: {
    ...mapActions("issue", ["loadIssue"]),

    updateRole(role: Role) {
      this.task.role = role.id;
    }
  },
  components: {
    CallOutForm,
    RoleSelect
  }
  //@ts-ignore
}).extendOptions;
