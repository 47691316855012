






import Vue from "vue";
import { FilterChoice } from "../../types";
import {
  FILTER_STATUS,
  FILTER_STORE,
  FILTER_REASON_CODE,
  FILTER_CASE_TYPE
} from "../../constants";
import { mapGetters, mapState } from "vuex";
import StatusFilter from "../CommonFilters/Available/StatusFilter.vue";
import AddFilter from "../RecordListing/AddFilter.vue";
import StoreFilter from "../CommonFilters/Available/StoreFilter.vue";
import ReasonCodeFilter from "../CommonFilters/Available/ReasonCodeFilter.vue";
import CaseTypeFilter from "./AvailableFilters/CaseTypeFilter.vue";

export default Vue.extend({
  computed: {
    ...mapGetters("listing", ["hasFilter"]),
    ...mapState(["stores"]),
    ...mapState("callOut", ["reasonCodes"]),

    choices(): FilterChoice[] {
      const options: FilterChoice[] = [];

      if (!this.hasFilter(FILTER_STATUS)) {
        options.push({
          value: FILTER_STATUS,
          display: "Status"
        });
      }

      if (this.stores.length && !this.hasFilter(FILTER_STORE)) {
        options.push({
          value: FILTER_STORE,
          display: "Home Store"
        });
      }

      if (this.reasonCodes.length && !this.hasFilter(FILTER_REASON_CODE)) {
        options.push({
          value: FILTER_REASON_CODE,
          display: "Reason Code"
        });
      }

      if (!this.hasFilter(FILTER_CASE_TYPE)) {
        options.push({
          value: FILTER_CASE_TYPE,
          display: "Type of Case"
        });
      }

      return options;
    }
  },

  components: {
    StatusFilter,
    StoreFilter,
    ReasonCodeFilter,
    CaseTypeFilter,
    AddFilter
  }
});
