

























import Vue from "vue";
import { CallOutReasonCode, SelectOption } from "../../../types";
import { mapState, mapActions } from "vuex";
import { FILTER_REASON_CODE } from "../../../constants";

export default Vue.extend({
  data() {
    return {
      selected: null as SelectOption | null
    };
  },

  computed: {
    ...mapState("callOut", ["reasonCodes"]),

    choices(): SelectOption[] {
      return this.reasonCodes.map(
        (reasonCode: CallOutReasonCode): SelectOption => ({
          display: reasonCode.code,
          value: reasonCode.id
        })
      );
    }
  },
  methods: {
    ...mapActions("listing", ["addFilter"]),
    add() {
      this.addFilter({
        type: FILTER_REASON_CODE,
        value: (this.selected as SelectOption).value,
        display: (this.selected as SelectOption).display
      });
    }
  }
});
