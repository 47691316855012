import { RootState, IssueState } from "resources/assets/types";
import { ActionTree } from "vuex";
import axios from "axios";
import { ISSUE_BASIC, ISSUE_CALL_OUT } from "../../../constants";

export default {
  async loadIssue({ commit, dispatch }, id) {
    const {
      data: { data }
    } = await axios.get(`/api/cases/${id}`);

    const { employee, issuable, ...issue } = data;

    commit("setIssue", issue);
    commit("setEmployee", employee, { root: true });

    switch (issuable.type) {
      case ISSUE_CALL_OUT:
        dispatch("callOut/setCallOut", issuable, { root: true });
        break;
      case ISSUE_BASIC:
        dispatch("basicCase/setBasicCase", issuable, { root: true });
        break;
      default:
        throw new Error("An unsupported issue type has been provided.");
    }
  }
} as ActionTree<IssueState, RootState>;
