

























import Vue from "vue";
import { SelectOption, Store } from "../../../types";
import { mapState, mapActions } from "vuex";
import { FILTER_STORE } from "../../../constants";

export default Vue.extend({
  data() {
    return {
      selected: []
    };
  },

  computed: {
    ...mapState(["stores"]),

    choices(): SelectOption[] {
      return this.stores.map(
        (store: Store): SelectOption => ({
          display: store.number,
          value: store.id
        })
      );
    }
  },
  methods: {
    ...mapActions("listing", ["addFilter"]),
    add() {
      this.addFilter({
        type: FILTER_STORE,
        choices: this.selected.map((c: SelectOption) => c.value)
      });
    }
  }
});
