import { RecordListingState } from "../../../types";

export default <RecordListingState>{
  pagination: {
    current: 1,
    last: 1,
    recordsPer: 1,
    recordsTotal: 1
  },
  page: 1,
  error: undefined,
  selectingAll: false,
  selected: [],
  records: [],
  filters: [],
  loading: false,
  search: ""
};
