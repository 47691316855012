



























import Vue from "vue";
import pluralize from "pluralize";

export default Vue.extend({
  props: {
    maxLength: {
      type: Number,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: (): boolean => false
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    error: {
      type: String,
      required: false
    },
    value: {
      type: String,
      required: false,
      default: (): string => ""
    }
  },

  computed: {
    hasError(): boolean {
      return this.error !== undefined && this.error.length > 0;
    }
  },

  methods: {
    pluralize,

    update(event: KeyboardEvent) {
      this.$emit("input", (event.target as HTMLInputElement).value);
    }
  }
});
