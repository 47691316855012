import Vue from "vue";

import LengthRestrictedInput from "../../components/LengthRestrictedInput.vue";

export const PageComponent = Vue.extend({
  data() {
    return {
      short_description: "",
      content: "",
      spanishContent: ""
    };
  },

  computed: {
    isValid(): boolean {
      if (this.content.trim().length <= 0) {
        return false;
      }

      if (this.short_description.trim().length <= 0) {
        return false;
      }

      return true;
    }
  },

  methods: {
    updateContent(e: KeyboardEvent) {
      this.content = (e.target as HTMLInputElement).value;
    }
  },

  components: {
    LengthRestrictedInput
  }

  //@ts-ignore
}).extendOptions;
