import { head } from "lodash";
import { DateTime } from "luxon";

import { Filter, OptionalString } from "./types";

type SelectorRoot = HTMLElement | Element | Document;

export const $$ = (
  selector: string,
  root: SelectorRoot = document
): HTMLElement[] => [...root.querySelectorAll<HTMLElement>(selector)];

export const $ = (
  selector: string,
  root: SelectorRoot = document
): HTMLElement | undefined => head($$(selector, root));

export const fail = (message: string) => {
  throw new Error(message);
};

export const formatFilterDate = (raw: string): string =>
  DateTime.fromSQL(raw).toFormat("LLLL d");

export const hasValue = (value: OptionalString): boolean =>
  value !== undefined && value.trim().length > 0;

export const getFilterParam = (
  filters: Filter[],
  type: string,
  param: string
): object => {
  const filter = filters.find((f: Filter) => f.type === type);

  if (filter) {
    return { [param]: filter.value ?? filter.choices };
  }

  return {};
};

export const supportNonGETMethodsOnLinks = () =>
  document.body.addEventListener("click", event => {
    if (!(event.target instanceof Element)) {
      return;
    }

    const el = event.target.closest("[data-method]");

    if (!(el instanceof HTMLElement)) {
      return;
    }

    if (!el.dataset.method) {
      return;
    }

    event.preventDefault();

    const confirmation = el.dataset.confirm;

    if (confirmation && !confirm(confirmation)) {
      return;
    }

    const form = document.createElement("form");

    form.setAttribute("hidden", "hidden");
    form.setAttribute("method", "POST");
    form.setAttribute("action", el.getAttribute("href") || "");

    const csrfInput = document.createElement("input");
    const csrfTokenMeta = $('meta[name="csrf-token"]');

    if (!csrfTokenMeta) {
      throw new Error(
        "No csrf-token meta tag is present; could not submit link form."
      );
    }

    csrfInput.setAttribute("type", "hidden");
    csrfInput.setAttribute("name", "_token");
    csrfInput.setAttribute(
      "value",
      csrfTokenMeta.getAttribute("content") || ""
    );

    const methodInput = document.createElement("input");

    methodInput.setAttribute("type", "hidden");
    methodInput.setAttribute("name", "_method");
    methodInput.setAttribute("value", (el.dataset.method || "").toUpperCase());

    form.appendChild(csrfInput);
    form.appendChild(methodInput);

    document.body.appendChild(form);

    form.submit();
  });

export const disableAutoCompleteOnForms = () => {
  $$("form:not([autocomplete]), input:not([autocomplete])").forEach(element =>
    element.setAttribute("autocomplete", "off")
  );
};
