import Issue from "./modules/Issue";
import CallOut from "./modules/CallOut";
import BasicCase from "./modules/BasicCase";
import RecordListing from "./modules/RecordListing";
import EmployeeListing from "./modules/EmployeeListing";
import { RootState } from "../types";
import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  state,
  mutations,
  actions,
  getters,
  modules: {
    employees: EmployeeListing,
    callOut: CallOut,
    basicCase: BasicCase,
    issue: Issue,
    listing: RecordListing
  }
});
