import "./bootstrap";

import { bootPage } from "./modules";
import {
  disableAutoCompleteOnForms,
  supportNonGETMethodsOnLinks
} from "./utils";

require("./css/app.scss");

document.addEventListener("DOMContentLoaded", () => {
  supportNonGETMethodsOnLinks();
  bootPage();
  disableAutoCompleteOnForms();
});
