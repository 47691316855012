import axios from "axios";
import {
  findPhoneNumbersInText,
  parsePhoneNumberFromString,
  PhoneNumber
} from "libphonenumber-js";
import { debounce, differenceBy, uniqBy } from "lodash";
import pluralize from "pluralize";
import Vue from "vue";

import LengthRestrictedInput from "../../components/LengthRestrictedInput.vue";
import { DEBOUNCE_DELAY } from "../../constants";

export const PageComponent = Vue.extend({
  watch: {
    rawRecipients: "updateRecipients",
    shouldSkipRegisteredEmployees() {
      this.updateRecipients();
    }
  },

  data() {
    return {
      content: "",
      shortDescription: "",
      rawRecipients: "",
      shouldSkipRegisteredEmployees: false,
      recipients: [] as PhoneNumber[],
      omitted: [] as PhoneNumber[]
    };
  },

  computed: {
    parsedRecipients(): PhoneNumber[] {
      return findPhoneNumbersInText(this.rawRecipients, "US").map(
        phoneNumber => phoneNumber.number
      );
    },

    uniqueRecipients(): PhoneNumber[] {
      return uniqBy(
        this.parsedRecipients,
        (phoneNumber: PhoneNumber) => phoneNumber.number
      );
    },

    /**
     * Get a count of the number of recipients rejected from the original input
     * to the final recipient pool because:
     *
     *  - the number was malformed
     *  - the number was a duplicate
     *  - the number is already registered with an employee and the admin chose to omit those
     */
    omittedNumbersCount(): number {
      return this.parsedRecipients.length - this.recipients.length;
    },

    /**
     * Check if the form can be submitted
     */
    isValid(): boolean {
      if (this.recipients.length === 0) {
        return false;
      }

      return this.content !== "" && this.shortDescription !== "";
    },

    bulkRecipientPhonesValue(): string {
      return JSON.stringify(
        this.recipients.map((phoneNumber: PhoneNumber) => phoneNumber.number)
      );
    }
  },

  methods: {
    pluralize,

    /**
     * Debounced method to kick off recompute of the final recipient pool.
     */
    updateRawRecipients: debounce(function(this: any, event: KeyboardEvent) {
      this.rawRecipients = (event.target as HTMLInputElement).value;
    }, DEBOUNCE_DELAY),

    /**
     * Compute the final recipient pool.
     */
    async updateRecipients() {
      if (this.uniqueRecipients.length === 0) {
        this.recipients = [];
        this.omitted = [];
        return;
      }

      if (!this.shouldSkipRegisteredEmployees) {
        this.recipients = this.uniqueRecipients;
        this.omitted = [];
        return;
      }

      const phones = this.uniqueRecipients.map(
        (phoneNumber: PhoneNumber) => phoneNumber.number
      );
      const {
        data: rawRegisteredPhones
      } = await axios.post("/api/employees/phones", { phones });

      this.omitted = rawRegisteredPhones.map((number: string) =>
        parsePhoneNumberFromString(number)
      );
      this.recipients = differenceBy(
        this.uniqueRecipients,
        this.omitted,
        (phoneNumber: PhoneNumber) => phoneNumber.number
      );
    },

    confirmDelivery(event: Event) {
      if (
        !confirm(
          `You are about to send this text message to ${pluralize(
            "recipient",
            this.uniqueRecipients.length,
            true
          )}. Are you sure you want to do this?`
        )
      ) {
        event.preventDefault();
        event.stopPropagation();

        return false;
      }

      return true;
    }
  },

  components: {
    LengthRestrictedInput
  }
  //@ts-ignore
}).extendOptions;
