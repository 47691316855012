







import Vue from "vue";
import { CallOutReasonCode } from "../../../types";
import { mapState, mapGetters } from "vuex";

export default Vue.extend({
  computed: {
    ...mapGetters("callOut", ["hasReason", "reason", "reasonCode"]),
    ...mapState("callOut", ["reasonCodes"]),
    reasonCodeWithDescription(): string | null {
      const reason = this.reasonCodes.find(
        (r: CallOutReasonCode) => r.id === this.reasonCode?.id
      );

      if (reason?.description) {
        return `${reason.code} - ${reason.description}`;
      }

      return this.reasonCode?.code ?? null;
    }
  }
});
