import CreateEmployeeGroup from "../../components/CreateEmployeeGroup.vue";
import EmployeeListing from "../../components/EmployeeListing/EmployeeListing.vue";
import Vue from "vue";
import { mapState } from "vuex";

export const PageComponent = Vue.extend({
  components: {
    EmployeeListing,
    CreateEmployeeGroup
  },

  computed: {
    ...mapState("listing", ["records"]),

    canCreateGroup() {
      return this.records.length > 0;
    }
  }
  //@ts-ignore
}).extendOptions;
