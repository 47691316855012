import { DateTime } from "luxon";
import {
  CallOutReasonCode,
  CallOutState,
  CovidTestResult
} from "resources/assets/types";
import { MutationTree } from "vuex";

type OptionalDate = DateTime | string | null;

const optionalDateToValue = (optionalDate: OptionalDate): string | null => {
  if (optionalDate === undefined) {
    return null;
  }

  if (optionalDate instanceof DateTime) {
    return optionalDate.toISO();
  }

  // it's a string
  return optionalDate;
};

export default {
  setCreatedAt(state, createdAt: string | null) {
    state.createdAt = createdAt;
  },

  setReasonCodes(state, reasonCodes: CallOutReasonCode[] = []) {
    state.reasonCodes = reasonCodes;
  },

  setQuestions(state, questions: string) {
    state.questions = questions;
  },

  setRequiresSickPay(state, required: boolean) {
    state.requiresSickPay = required;
  },

  setRequiresPtoPay(state, required: boolean) {
    state.requiresPtoPay = required;
  },

  setReasonCodeID(state, id: number | null) {
    state.reasonCodeID = id;
  },

  setReason(state, reason: string) {
    state.reason = reason;
    state.reasonCodeID = null;
  },

  setDateRange(
    state,
    { from, to }: { from: OptionalDate; to: OptionalDate } = {
      from: null,
      to: null
    }
  ) {
    if (from === null || to === null) {
      state.startsAt = null;
      state.endsAt = null;

      return;
    }

    state.startsAt = optionalDateToValue(from) as string;
    state.endsAt = optionalDateToValue(to) as string;
  },

  setCovidTested(state, tested: boolean) {
    state.covidTested = tested;
  },

  setCovidTestResults(state, result: CovidTestResult) {
    state.covidTestResults = result;
  },

  setCovidContact(state, contact: boolean) {
    state.covidContact = contact;
  },

  setCovidSymptoms(state, symptoms: boolean) {
    state.covidSymptoms = symptoms;
  },

  setCovidExposedOn(state, exposed: OptionalDate) {
    state.covidExposedOn = optionalDateToValue(exposed) || null;
  },

  setCovidExposureWasWorkRelated(state, wasWorkRelated: boolean) {
    state.covidExposureWasWorkRelated = wasWorkRelated;
  },

  setIsImmunocompromised(state, immunocompromised: boolean) {
    state.isImmunocompromised = immunocompromised;
  },

  setHasDoctorsNote(state, hasNote: boolean) {
    state.hasDoctorsNote = hasNote;
  },

  setCaresForHighRiskPerson(state, doesCare: boolean) {
    state.caresForHighRiskPerson = doesCare;
  }
} as MutationTree<CallOutState>;
