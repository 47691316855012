













import Vue from "vue";
import { isEmpty } from "lodash";
import { mapState, mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: (): boolean => false
    }
  },
  computed: {
    ...mapState("callOut", ["reason", "questions"]),
    ...mapGetters("callOut", ["hasDateRange"]),

    /**
     * This should be visible once a reason and date range have been set. The
     * reason does not matter.
     */
    isVisible(): boolean {
      return !isEmpty(this.reason) && this.hasDateRange;
    }
  },

  methods: {
    ...mapMutations("callOut", ["setQuestions"]),

    updateQuestions(event: KeyboardEvent) {
      this.setQuestions((event.currentTarget as HTMLInputElement).value || "");
    }
  }
});
