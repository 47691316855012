




































import Vue from "vue";
import { mapActions } from "vuex";
import { FILTER_SCHEDULED } from "../../../constants";
import { isNil } from "lodash";

export default Vue.extend({
  data() {
    return {
      isWorking: true,
      date: undefined as string | undefined
    };
  },

  computed: {
    isValid(): boolean {
      return !isNil(this.date) && /^\d{4}-\d{2}-\d{2}$/.test(this.date);
    }
  },

  methods: {
    ...mapActions("listing", ["addFilter"]),

    add() {
      this.addFilter({
        type: FILTER_SCHEDULED,
        value: {
          is_working: Number(this.isWorking),
          date: this.date
        }
      });
    }
  }
});
