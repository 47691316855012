import { RecordListingState, RootState, Record } from "../../../types";

import { GetterTree } from "vuex";

export default {
  selectedCount: (state): number =>
    state.selectingAll ? state.pagination.recordsTotal : state.selected.length,
  hasSelection: (_, getters): boolean => getters.selectedCount > 0,
  isSelected: state => (record: Record): boolean =>
    state.selected.includes(record.id),
  hasFilter: state => (type: string): boolean =>
    state.filters.some(f => f.type === type)
} as GetterTree<RecordListingState, RootState>;
