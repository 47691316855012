import { render, staticRenderFns } from "./CovidTestedForm.vue?vue&type=template&id=5c296d56&scoped=true&"
import script from "./CovidTestedForm.vue?vue&type=script&lang=ts&"
export * from "./CovidTestedForm.vue?vue&type=script&lang=ts&"
import style0 from "./CovidTestedForm.vue?vue&type=style&index=0&id=5c296d56&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c296d56",
  null
  
)

export default component.exports