import { get } from "lodash";
import Vue from "vue";

import EmployeeListing from "../../components/EmployeeListing/EmployeeListing.vue";

export const PageComponent = Vue.extend({
  components: {
    EmployeeListing
  },

  methods: {
    pluck: get
  }

  //@ts-ignore
}).extendOptions;
