import {
  AddFilterMutation,
  RecordListingState,
  Pagination,
  Record
} from "../../../types";

import { MutationTree } from "vuex";
import shortid from "shortid";

export default {
  setError(state, error = undefined) {
    state.error = error;
  },

  setLoading(state, loading = true) {
    state.loading = loading;
  },

  setRecords(state, records: Record[] = []) {
    state.records = records;
  },
  setPagination(
    state,
    { current, last, recordsTotal, recordsPer }: Pagination
  ) {
    state.pagination = { current, last, recordsTotal, recordsPer };
  },

  setPage(state, page = 1) {
    state.page = page;
  },

  setSearch(state, search = "") {
    state.search = search;
  },

  selectAll(state) {
    state.selectingAll = true;
    state.selected = [];
  },

  deselectAll(state) {
    state.selectingAll = false;
    state.selected = [];
  },

  toggleSelection(state, record: Record) {
    const index = state.selected.findIndex(id => id === record.id);

    if (index !== -1) {
      state.selected.splice(index, 1);
    } else {
      state.selected.push(record.id);
    }
  },
  addFilter(state, mutation: AddFilterMutation) {
    state.filters.push({
      ...mutation,
      cid: shortid.generate()
    });
  },

  removeFilter(state, cid: string) {
    const index = state.filters.findIndex(f => f.cid === cid);

    if (index !== -1) {
      state.filters.splice(index, 1);
    }
  }
} as MutationTree<RecordListingState>;
