

























import Vue from "vue";
import { SelectOption } from "../../../types";
import { mapActions } from "vuex";
import { FILTER_CASE_TYPE } from "../../../constants";

export default Vue.extend({
  data() {
    return {
      selected: null as SelectOption | null
    };
  },

  computed: {
    choices(): SelectOption[] {
      return [
        {
          display: "Basic",
          value: "basic"
        },
        {
          display: "Call Out",
          value: "call-out"
        }
      ];
    }
  },
  methods: {
    ...mapActions("listing", ["addFilter"]),
    add() {
      if (this.selected === null) {
        return;
      }

      this.addFilter({
        type: FILTER_CASE_TYPE,
        value: (this.selected as SelectOption).value
      });
    }
  }
});
