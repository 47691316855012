

















import Vue from "vue";
import { FilterChoice } from "../../types";
import container from "../../services";
import { mapState } from "vuex";

export default Vue.extend({
  props: {
    choices: {
      type: Array as () => FilterChoice[],
      required: true
    }
  },

  created() {
    container
      .resolve<Vue>("bus")
      .$on("listing:reset", () => (this.choice = null));
  },

  data() {
    return {
      choice: null as FilterChoice | null
    };
  },

  computed: {
    ...mapState("listing", ["loading"])
  }
});
