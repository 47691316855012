














import Vue from "vue";
import { debounce } from "lodash";
import { mapState, mapMutations } from "vuex";
import { DEBOUNCE_DELAY } from "../../constants";

export default Vue.extend({
  data() {
    return {
      timeout: undefined as number | undefined
    };
  },

  computed: {
    ...mapState("listing", ["loading", "search", "error"]),

    isDisabled(): boolean {
      if (
        this.$el &&
        document.activeElement === this.$el.querySelector("input[type=search")
      ) {
        return false;
      }

      return this.loading || this.error;
    }
  },

  methods: {
    ...mapMutations("listing", ["setSearch"]),

    updateSearch(e: KeyboardEvent) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(
        () => this.setSearch((e.target as HTMLInputElement).value.trim()),
        DEBOUNCE_DELAY
      );
    }
  }
});
