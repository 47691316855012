

































import Vue from "vue";
import { isNil } from "lodash";
import { Message } from "../types";

export default Vue.extend({
  props: {
    message: {
      type: Object as () => Message,
      required: true
    },
    href: {
      type: String,
      required: false
    }
  },

  computed: {
    hasSpanish(): boolean {
      return (
        !isNil(this.message.spanish_content) &&
        this.message.spanish_content.length > 0
      );
    }
  }
});
