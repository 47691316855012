

























import Vue from "vue";
import { SelectOption, Store } from "../../../types";
import { mapState, mapActions } from "vuex";
import { FILTER_STATUS } from "../../../constants";

export default Vue.extend({
  data() {
    return {
      selected: null
    };
  },

  computed: {
    choices(): SelectOption[] {
      return [
        {
          display: "To Do",
          value: "to do"
        },
        {
          display: "Complete",
          value: "complete"
        }
      ];
    }
  },
  methods: {
    ...mapActions("listing", ["addFilter"]),
    add() {
      if (this.selected === null) {
        return;
      }

      this.addFilter({
        type: FILTER_STATUS,
        value: ((this.selected as unknown) as SelectOption).value
      });
    }
  }
});
