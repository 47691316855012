

















import Vue from "vue";
import { CALLOUT_REASONS } from "../../../constants";
import { mapState, mapMutations, mapGetters } from "vuex";
import { CallOutReason } from "../../../types";
import ReasonCode from "./ReasonCode.vue";

export default Vue.extend({
  data() {
    return {
      reasons: CALLOUT_REASONS
    };
  },

  computed: {
    ...mapState("callOut", ["reason"]),
    ...mapGetters("callOut", ["reasonCode"]),

    selectedReason(): CallOutReason | undefined {
      return this.reasons.find(r => r.type === this.reason);
    }
  },

  methods: {
    ...mapMutations("callOut", ["setReason"]),

    updateReason(reason: CallOutReason | null) {
      this.setReason(reason ? reason.type : undefined);
    }
  },

  components: {
    ReasonCode
  }
});
