










import Vue from "vue";
import { DateTime } from "luxon";

export default Vue.extend({
  props: {
    timestamp: {
      required: false,
      type: String
    },
    fallback: {
      type: String,
      required: false,
      default: (): string => "Not Available"
    }
  }
});
