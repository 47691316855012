import { ServiceFactory, Resolver } from "./types";
import ServiceSingleton from "./ServiceSingleton";
import ServiceInstance from "./ServiceInstance";

/**
 * The service container.
 */
export default class Container {
  protected services: ServiceFactory<any>[] = [];

  constructor() {
    //
  }

  public factory<T>(what: string, resolver: Resolver<T>): void {
    this.register<T>(new ServiceSingleton<T>(what, resolver));
  }

  public instance<T>(what: string, resolver: Resolver<T>): void {
    this.register<T>(new ServiceInstance<T>(what, resolver));
  }

  public register<T>(service: ServiceFactory<T>, force = false): void {
    if (!force && this.exists(service.identifier)) {
      throw new Error(
        `A service with identifier [${service.identifier}] has already been registered`
      );
    }

    this.services.push(service);
  }

  public exists(identifier: string): boolean {
    return this.keys().includes(identifier);
  }

  public locate<T>(identifier: string): ServiceFactory<T> | undefined {
    return this.services.find(
      (service: ServiceFactory<any>) => service.identifier === identifier
    );
  }

  public locateOrFail<T>(identifier: string): ServiceFactory<T> {
    const service = this.locate<T>(identifier);

    if (service === undefined) {
      throw new Error(
        `Could not locate [${identifier}] for service resolution.`
      );
    }

    return service;
  }

  public resolve<T>(identifier: string): T {
    return this.locateOrFail<T>(identifier).resolve();
  }

  protected keys(): string[] {
    return Object.keys(this.services);
  }
}
