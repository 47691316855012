







































































import Vue from "vue";
import { isEmpty } from "lodash";
import { mapState, mapGetters, mapMutations } from "vuex";
export default Vue.extend({
  data() {
    return {};
  },

  computed: {
    ...mapState("callOut", ["reason", "requiresPtoPay", "requiresSickPay"]),
    ...mapState(["employee"]),
    ...mapGetters("callOut", ["hasDateRange"]),
    ...mapState("issue", ["hasGivenVCCSEnrollmentReminder"]),

    /**
     * This should be visible once a reason and date range have been set. The
     * reason does not matter.
     */
    isVisible(): boolean {
      return !isEmpty(this.reason) && this.hasDateRange;
    }
  },

  methods: {
    ...mapMutations("callOut", ["setRequiresSickPay", "setRequiresPtoPay"]),
    ...mapMutations("issue", ["setHasGivenVCCSEnrollmentReminder"]),
    ...mapMutations(["setHasKronosMobile"])
  }
});
