

























import Vue from "vue";
import { SelectOption, Department } from "../../../types";
import { mapState, mapActions } from "vuex";
import { FILTER_DEPARTMENT } from "../../../constants";

export default Vue.extend({
  data() {
    return {
      selected: []
    };
  },

  computed: {
    ...mapState(["departments"]),

    choices(): SelectOption[] {
      const departments = this.departments;

      departments.sort((a: Department, b: Department) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        if (a.name && !b.name) {
          return 1;
        }
        if (!a.name && b.name) {
          return -1;
        }
        return Number(String(a.number).replace(/^\0+/, "")) <=
          Number(String(b.number).replace(/^\0+/, ""))
          ? -1
          : 1;
      });

      const options = departments.map(
        (department: Department): SelectOption => ({
          display: `${department.name || "(No Name)"} - #${department.number}`,
          value: department.id
        })
      );

      return options;
    }
  },

  methods: {
    ...mapActions("listing", ["addFilter"]),

    add() {
      this.addFilter({
        type: FILTER_DEPARTMENT,
        choices: this.selected.map((d: SelectOption) => d.value)
      });
    }
  }
});
