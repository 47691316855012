





























import Vue from "vue";
import axios from "axios";
import { Role } from "../types";
import { difference } from "lodash";

export default Vue.extend({
  props: {
    selected: {
      required: true,
      type: Array as () => number[]
    },
    multiple: {
      required: false,
      type: Boolean,
      default: (): boolean => false
    }
  },

  async created() {
    const {
      data: { data: roles }
    } = await axios.get("/api/roles");

    this.roles = roles;
    this.loading = false;
  },

  data() {
    return {
      loading: true,
      roles: [] as Role[]
    };
  },

  computed: {
    options(): Role[] {
      return difference(this.roles, this.selectedRoles);
    },

    selectedRoles(): Role[] {
      return this.selected
        .map((id: number) => this.roles.find((r: Role) => r.id === id))
        .filter((role: Role | undefined) => role !== undefined) as Role[];
    }
  }
});
