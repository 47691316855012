





















































import Vue from "vue";
import axios from "axios";
import qs from "qs";
import { Employee, User, QueryParams } from "../types";

type Individual = Employee | User;

export default Vue.extend({
  props: {
    fieldName: {
      type: String,
      required: false,
      default: (): string => "users[]"
    },
    endpoint: {
      type: String,
      required: true
    },
    queryParams: {
      type: Object,
      required: false,
      default: (): QueryParams => ({})
    },
    initiallySelected: {
      type: Array,
      required: false,
      default: (): number[] => []
    }
  },

  watch: {
    selected(value) {
      this.$emit("change", value);
    }
  },

  async created() {
    if (this.initiallySelected.length === 0) {
      return;
    }

    const params = {
      ...this.queryParams,
      ids: this.initiallySelected
    };

    try {
      const {
        data: { data: users }
      } = await axios.get(this.endpoint, {
        params
      });

      this.selected = users;
    } catch (error) {
      this.error =
        "There was a problem fetching the currently set store contacts. Try again later.";
    }
  },

  data() {
    return {
      selected: [],
      options: [],
      loading: false,
      error: null as string | null
    };
  },

  methods: {
    label(user: Individual): string {
      return `${user.first_name} ${user.last_name}`;
    },

    remove(user: Individual) {
      const index = this.selected.findIndex(
        (e: Individual) => e.id === user.id
      );

      if (index < 0) {
        return;
      }

      this.selected.splice(index, 1);
    },

    async search(query: string) {
      this.error = null;
      this.loading = true;

      const params = {
        ...this.queryParams,
        search: query
      };

      try {
        const {
          data: { data: users }
        } = await axios.get(this.endpoint, {
          params,
          paramsSerializer: params => qs.stringify(params, { encode: false })
        });

        this.options = users;
      } catch (error) {
        this.error = "An unexpected error occured. Please try again later.";
      } finally {
        this.loading = false;
      }
    }
  }
});
