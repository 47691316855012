import CallOutForm from "../../../components/Issues/CallOut/CallOutForm.vue";
import Vue from "vue";
import { mapActions } from "vuex";

export const PageComponent = Vue.extend({
  props: {
    employeeId: {
      required: true,
      type: Number
    }
  },
  created() {
    this.loadEmployee(this.employeeId);
  },
  methods: {
    ...mapActions(["loadEmployee"])
  },
  components: {
    CallOutForm
  }
  //@ts-ignore
}).extendOptions;
