import { isEmpty, isNil } from "lodash";
import { GetterTree } from "vuex";

const inferReasonCodeFromState = (state: CallOutState): string | null => {
  switch (state.reason) {
    case CALLOUT_REASON_FORCED_QUARANTINE:
      return "L50";
    case CALLOUT_REASON_DIRECT_CONTACT:
      return "L51";
    case CALLOUT_REASON_OTHER:
      return "L0";
    case CALLOUT_REASON_COVID_POSITIVE:
      return "L58";
    case CALLOUT_REASON_CHILD_CARE:
      return "L59";
    case CALLOUT_REASON_TRANSPORTATION:
    case CALLOUT_REASON_SCHEDULE_ISSUE:
    case CALLOUT_REASON_ACCOMMODATION:
      return "LT0";
    case CALLOUT_REASON_RESIGNED:
      return "T";
    case CALLOUT_REASON_SICK:
      if (state.covidTested) {
        switch (state.covidTestResults) {
          case COVID_RESULTS_POSITIVE:
            return "L58";
          case COVID_RESULTS_NEGATIVE:
            return "LT0";
          case COVID_RESULTS_PENDING:
          default:
            return "L55";
        }
      }

      if (state.covidContact) {
        if (state.covidExposureWasWorkRelated) {
          return "L50";
        }

        return "L51";
      }

      return "LT0"; // not sick, no contact, no symptoms
    case CALLOUT_REASON_SELF_QUARANTINE:
      if (state.isImmunocompromised) {
        return "L53";
      }

      if (state.caresForHighRiskPerson) {
        return "L54";
      }

      return "L52";
    default:
      return null;
  }
};

import {
  CALLOUT_REASON_ACCOMMODATION,
  CALLOUT_REASON_CHILD_CARE,
  CALLOUT_REASON_COVID_POSITIVE,
  CALLOUT_REASON_DIRECT_CONTACT,
  CALLOUT_REASON_FORCED_QUARANTINE,
  CALLOUT_REASON_OTHER,
  CALLOUT_REASON_RESIGNED,
  CALLOUT_REASON_SCHEDULE_ISSUE,
  CALLOUT_REASON_SELF_QUARANTINE,
  CALLOUT_REASON_SICK,
  CALLOUT_REASON_TRANSPORTATION,
  CALLOUT_REASONS,
  COVID_RESULTS_NEGATIVE,
  COVID_RESULTS_PENDING,
  COVID_RESULTS_POSITIVE
} from "../../../constants";
import {
  CallOutReason,
  CallOutReasonCode,
  CallOutState,
  RootState
} from "../../../types";

export default {
  hasDateRange: state => !isNil(state.startsAt) && !isNil(state.endsAt),
  hasReason: (_, getters): boolean => getters.reason !== undefined,
  reason: (state): string | undefined =>
    CALLOUT_REASONS.find(
      (reason: CallOutReason) => reason.type === state.reason
    )?.display,
  reasonCode: (state): CallOutReasonCode | null => {
    // does the call out have a reason set already? if so, find and use it.
    if (state?.reasonCodeID !== null) {
      const reason = state.reasonCodes.find(
        (r: CallOutReasonCode) => Number(r.id) === Number(state.reasonCodeID)
      );

      if (reason) {
        return reason;
      }
    }

    // can we infer a reason from the rest of the state? if so...use it!
    let reasonCode = inferReasonCodeFromState(state);

    if (reasonCode === null) {
      return null;
    }

    const reason = state.reasonCodes.find(
      (r: CallOutReasonCode) => r.code === reasonCode
    );

    return reason ?? null;
  }
} as GetterTree<CallOutState, RootState>;
