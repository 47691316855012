











import Vue from "vue";
import StatusFilter from "../CommonFilters/Applied/StatusFilter.vue";
import RoleFilter from "../CommonFilters/Applied/RoleFilter.vue";
import { Filter } from "../../types";
import ReasonCodeFilter from "../CommonFilters/Applied/ReasonCodeFilter.vue";
import DueOnFilter from "./AppliedFilters/DueOnFilter.vue";

export default Vue.extend({
  props: {
    filters: {
      required: true,
      type: Array as () => Filter[]
    }
  },

  components: {
    StatusFilter,
    RoleFilter,
    ReasonCodeFilter,
    DueOnFilter
  }
});
