




































import Vue from "vue";
import { SelectOption, Message } from "../../../types";
import { mapGetters, mapActions } from "vuex";
import { FILTER_RESPONSE, MATCH_EXACT } from "../../../constants";

export default Vue.extend({
  data() {
    return {
      question: undefined,
      selected: []
    };
  },

  computed: {
    ...mapGetters("employees", ["unusedQuestions", "hasFilter"]),

    isValid(): boolean {
      return this.question !== undefined && this.selected.length > 0;
    },

    questions(): SelectOption[] {
      return this.unusedQuestions.map(
        (m: Message): SelectOption => ({
          value: m.id,
          display: m.short_description
        })
      );
    },

    responses(): SelectOption[] {
      return [
        { display: "Yes", value: "yes" },
        { display: "No", value: "no" },
        { display: "Unanswered", value: "(((no response)))" }
      ];
    }
  },

  methods: {
    ...mapActions("listing", ["addFilter"]),

    add() {
      this.addFilter({
        type: FILTER_RESPONSE,
        match: MATCH_EXACT,
        value: ((this.question as unknown) as SelectOption).value,
        choices: this.selected.map((c: SelectOption) => c.value)
      });
    }
  }
});
