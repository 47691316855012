












































import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import {
  COVID_RESULTS_NEGATIVE,
  COVID_RESULTS_PENDING,
  COVID_RESULTS_POSITIVE,
  CALLOUT_REASON_SICK
} from "../../../constants";

/**
 * If the employee has indicated they have been tested for COVID-19 on the
 * CovidScreeningForm, they will be asked if they tested positive or not.
 */
export default Vue.extend({
  data() {
    return {
      COVID_RESULTS_POSITIVE,
      COVID_RESULTS_NEGATIVE,
      COVID_RESULTS_PENDING
    };
  },

  computed: {
    ...mapState("callOut", ["reason", "covidTested", "covidTestResults"]),

    isVisible(): boolean {
      return [CALLOUT_REASON_SICK].includes(this.reason) && this.covidTested;
    }
  },

  methods: {
    ...mapMutations("callOut", ["setCovidTestResults"])
  }
});
