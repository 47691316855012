import axios from "axios";
import { debounce } from "lodash";
import pluralize from "pluralize";
import Vue from "vue";

import LengthRestrictedInput from "../../components/LengthRestrictedInput.vue";
import { DEBOUNCE_DELAY } from "../../constants";

interface ProbeTrigger {
  valid: boolean;
  phrase: string;
}

export const PageComponent = Vue.extend({
  props: {
    probeId: {
      required: false,
      type: Number
    },
    initialShortDescription: {
      required: false,
      type: String
    },
    initialReply: {
      required: false,
      type: String
    },
    initialSpanishReply: {
      required: false,
      type: String
    },
    initialPhrases: {
      required: false,
      type: String
    }
  },

  created() {
    if (this.initialPhrases.trim().length > 0) {
      this.updateTriggers();
    }
  },

  watch: {
    phrases: "updateTriggers"
  },

  data() {
    return {
      reply: this.initialReply || "",
      spanishReply: this.initialSpanishReply || "",
      shortDescription: this.initialShortDescription || "",
      phrases: this.initialPhrases || "",
      triggers: [] as ProbeTrigger[]
    };
  },

  computed: {
    /**
     * Check if the form can be submitted
     */
    isValid(): boolean {
      if (this.validTriggers.length === 0) {
        return false;
      }

      return this.reply !== "" && this.shortDescription !== "";
    },

    validTriggers(): ProbeTrigger[] {
      return this.triggers.filter(trigger => trigger.valid);
    }
  },

  methods: {
    pluralize,

    /**
     * Debounced method to kick off recompute of the final recipient pool.
     */
    updatePhrases: debounce(function(this: any, event: KeyboardEvent) {
      this.phrases = (event.target as HTMLInputElement).value;
    }, DEBOUNCE_DELAY),

    /**
     * Compute the final recipient pool.
     */
    async updateTriggers() {
      const phraseFormatRegex = /^[a-z0-9_](.*[a-z0-9_])?$/;

      const phrases = this.phrases
        .split(/\s*,\s*/)
        .filter(phrase => phrase.trim().length > 0)
        .map(phrase => phrase.trim().toLowerCase());

      const rejectedPhrasesDueToFormat = phrases.filter(
        phrase => !phraseFormatRegex.test(phrase)
      );

      const phrasesToConsiderFurther = phrases.filter(
        phrase => !rejectedPhrasesDueToFormat.includes(phrase)
      );

      if (!phrases.length) {
        this.triggers = [];

        return;
      }

      // check for duplicates
      const params: { [key: string]: any } = {};
      const data = { phrases: phrasesToConsiderFurther };

      if (this.probeId) {
        params.id = this.probeId;
      }

      const { data: takenPhrases } = await axios.post(
        "/api/probes/phrases",
        data,
        { params }
      );

      this.triggers = phrasesToConsiderFurther
        .map(
          (phrase): ProbeTrigger => ({
            valid: !takenPhrases.includes(phrase),
            phrase
          })
        )
        .concat(
          rejectedPhrasesDueToFormat.map(
            (phrase): ProbeTrigger => ({
              valid: false,
              phrase
            })
          )
        )
        .sort((a, b) =>
          Math.sign(phrases.indexOf(a.phrase) - phrases.indexOf(b.phrase))
        );
    }
  },

  components: {
    LengthRestrictedInput
  }
  //@ts-ignore
}).extendOptions;
