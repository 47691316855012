




















import Vue from "vue";
import AppliedFilter from "../../RecordListing/AppliedFilter.vue";
import { Filter, SelectOption, Department, Store } from "../../../types";
import { mapState } from "vuex";
import { isNil } from "lodash";

export default Vue.extend({
  props: {
    filter: {
      required: true,
      type: Object as () => Filter
    }
  },
  computed: {
    ...mapState(["stores"]),

    choices(): SelectOption[] {
      if (isNil(this.filter.choices)) {
        throw new Error("The [store] filter is missing a choice.");
      }

      return this.filter.choices.map(
        (id): SelectOption => ({
          value: id,
          display: this.stores.find(
            (store: Store): boolean => Number(store.id) === Number(id)
          ).number
        })
      );
    }
  },
  components: {
    AppliedFilter
  }
});
