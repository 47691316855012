import { COVID_RESULTS_PENDING } from "../../../constants";
import { CallOutState } from "../../../types";

export default <CallOutState>{
  // for task & issue listings, a collection of unique reason codes
  // is fetched from the API and stored here to populate the 'Reason Code' filter.
  reasonCodes: [],

  // the rest of the state is used to track the creation and editing of a call out.
  reason: null,
  createdAt: null, // used to check if questions can be modified
  startsAt: null, // call out date (COD)
  endsAt: null, // return to work (RTW)

  // standard questions
  requiresSickPay: false,
  requiresPtoPay: false,
  questions: null,

  // custom reason code
  reasonCodeID: null,

  // COVID screening
  covidTested: false,
  covidContact: false,
  covidSymptoms: false,

  // COVID tested
  covidTestResults: COVID_RESULTS_PENDING,

  // COVID exposure
  covidExposedOn: null, // date of exposure (DOE)
  covidExposureWasWorkRelated: false,

  // COVID self quarantine
  isImmunocompromised: null,
  hasDoctorsNote: null,
  caresForHighRiskPerson: null
};
