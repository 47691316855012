




import Vue from "vue";
import { MessageType } from "../types";

export default Vue.extend({
  props: {
    type: {
      type: String as () => MessageType,
      required: true
    }
  },

  computed: {
    displayType(): string {
      return this.type.charAt(0).toUpperCase() + this.type.slice(1);
    }
  }
});
