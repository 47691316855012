
























import Vue from "vue";
import { STATUS_CLOSED, STATUS_OPEN } from "../constants";

export default Vue.extend({
  props: {
    openStatus: {
      required: false,
      type: String,
      default: (): string => STATUS_OPEN
    },
    closedStatus: {
      required: false,
      type: String,
      default: (): string => STATUS_CLOSED
    },
    status: {
      required: true,
      type: String
    }
  },
  computed: {
    isOpen(): boolean {
      return this.status === this.openStatus;
    }
  }
});
