










import Vue from "vue";
import AppliedFilter from "../../RecordListing/AppliedFilter.vue";
import { Filter } from "../../../types";
import { formatFilterDate } from "../../../utils";

export default Vue.extend({
  props: {
    filter: {
      required: true,
      type: Object as () => Filter
    }
  },
  methods: {
    formatFilterDate
  },
  components: {
    AppliedFilter
  }
});
