






import Vue from "vue";
import {
  Message,
  Response,
  Filter,
  Store,
  Department,
  SelectOption,
  EmployeeGroup,
  FilterChoice
} from "../../types";
import shortid from "shortid";
import {
  MATCH_EXACT,
  FILTER_DEPARTMENT,
  FILTER_STORE,
  FILTER_RESPONSE,
  FILTER_GROUP,
  FILTER_CLOSE_CONTACT,
  FILTER_SCHEDULED
} from "../../constants";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { isNil } from "lodash";
import StoreFilter from "../CommonFilters/Available/StoreFilter.vue";
import DepartmentFilter from "./AvailableFilters/DepartmentFilter.vue";
import GroupFilter from "./AvailableFilters/GroupFilter.vue";
import CloseContactFilter from "./AvailableFilters/CloseContactFilter.vue";
import ScheduledFilter from "./AvailableFilters/ScheduledFilter.vue";
import ResponseFilter from "./AvailableFilters/ResponseFilter.vue";
import AddFilter from "../RecordListing/AddFilter.vue";

export default Vue.extend({
  computed: {
    ...mapState("employees", ["questions"]),
    ...mapState(["stores", "departments", "groups"]),
    ...mapGetters("listing", ["hasFilter"]),
    ...mapGetters("employees", ["unusedQuestions"]),

    choices(): FilterChoice[] {
      const options: FilterChoice[] = [];

      if (this.unusedQuestions.length) {
        options.push({
          value: FILTER_RESPONSE,
          display: "Message Response"
        });
      }

      if (this.stores.length && !this.hasFilter(FILTER_STORE)) {
        options.push({
          value: FILTER_STORE,
          display: "Home Store"
        });
      }

      if (this.departments.length && !this.hasFilter(FILTER_DEPARTMENT)) {
        options.push({
          value: FILTER_DEPARTMENT,
          display: "Home Department"
        });
      }

      if (this.groups.length && !this.hasFilter(FILTER_GROUP)) {
        options.push({
          value: FILTER_GROUP,
          display: "Employee Group"
        });
      }

      if (!this.hasFilter(FILTER_CLOSE_CONTACT)) {
        options.push({
          value: FILTER_CLOSE_CONTACT,
          display: "Close Contact"
        });
      }

      if (!this.hasFilter(FILTER_SCHEDULED)) {
        options.push({
          value: FILTER_SCHEDULED,
          display: "Schedule"
        });
      }

      return options;
    }
  },

  components: {
    StoreFilter,
    GroupFilter,
    ResponseFilter,
    DepartmentFilter,
    CloseContactFilter,
    ScheduledFilter,

    AddFilter
  }
});
