





































































































import Vue from "vue";
import { CALLOUT_REASON_SICK } from "../../../constants";
import { mapState, mapGetters, mapMutations } from "vuex";

/**
 * If the employee is sick, they need to be screened for COVID-19 infection.
 */
export default Vue.extend({
  computed: {
    ...mapState("callOut", [
      "reason",
      "covidTested",
      "covidContact",
      "covidSymptoms"
    ]),
    ...mapGetters("callOut", ["hasDateRange"]),

    /**
     * This form should be visible when the reason for the call out is that the
     * employee is sick and a date range has been provided.
     */
    isVisible(): boolean {
      return this.reason === CALLOUT_REASON_SICK && this.hasDateRange;
    }
  },

  methods: {
    ...mapMutations("callOut", [
      "setCovidTested",
      "setCovidContact",
      "setCovidSymptoms"
    ])
  }
});
