































import { CallOutReasonCode } from "resources/assets/types";
import Vue from "vue";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import uniqBy from "lodash/uniqBy";

export default Vue.extend({
  mounted() {
    this.loadReasonCodes();
  },

  watch: {
    // this watch ensures once the first reason code id is set - often from props
    // passed in from the blade view - it will be stored for the duration of the
    // page, making a no-longer-approved code available in the select even if the
    // user selects another option.
    reasonCodeID: {
      handler(value: number | null) {
        if (this.originalID === null) {
          this.originalID = value;
        }
      },
      immediate: true
    }
  },

  data() {
    return {
      originalID: null as number | null
    };
  },

  computed: {
    ...mapState("callOut", ["reason", "reasonCodes", "reasonCodeID"]),
    ...mapGetters("callOut", ["reasonCode"]),

    options() {
      let options = [...this.reasonCodes];

      // now sort, first by approved & not readonly, then by readonly
      options.sort((a: CallOutReasonCode, b: CallOutReasonCode) => {
        if (a.readonly) {
          return -1;
        } else if (b.readonly) {
          return 1;
        }

        return 0;
      });

      return options;
    }
  },

  methods: {
    ...mapMutations("callOut", ["setReasonCodeID"]),
    ...mapActions("callOut", ["loadReasonCodes"]),

    updateReasonCode(reasonCode: CallOutReasonCode) {
      this.setReasonCodeID(reasonCode.id);
    }
  }
});
