

































































import Vue from "vue";
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
import EmployeeShift from "./EmployeeShift.vue";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import { hasValue } from "../../../utils";
import { OptionalString } from "../../../types";

export default Vue.extend({
  async created() {
    const params = {
      employee: this.employee.id,
      upcoming: Number(true)
    };
    const {
      data: { data: shifts }
    } = await axios.get("/api/shifts", { params });

    this.shifts = shifts;
    this.selectingShifts = false; //shifts.length > 0;
  },

  watch: {
    from: "syncDateRange",
    to: "syncDateRange"
  },

  data() {
    return {
      selectingShifts: false,
      shifts: [],
      from: undefined as OptionalString,
      to: undefined as OptionalString
    };
  },

  computed: {
    ...mapState(["employee"]),
    ...mapState("callOut", ["reason", "startsAt", "endsAt"]),
    ...mapGetters("callOut", ["hasDateRange"]),

    isVisible(): boolean {
      return !isEmpty(this.reason);
    },

    hasShifts(): boolean {
      return false; // for now the ability to select shifts for to get a date-range is disabled
      return this.shifts.length > 0;
    },

    isInvalidEndDate(): boolean {
      if (!hasValue(this.from) || !hasValue(this.to)) {
        return false;
      }

      return (
        DateTime.fromSQL(this.from as string) >
        DateTime.fromSQL(this.to as string)
      );
    },

    isStartDateTooEarly(): boolean {
      if (!hasValue(this.from)) {
        return false;
      }

      return (
        DateTime.fromSQL(this.from as string) <
        DateTime.local().minus({ months: 2 })
      );
    }
  },

  methods: {
    ...mapMutations("callOut", ["setDateRange"]),

    toggleMode() {
      this.selectingShifts = !this.selectingShifts;
      this.setDateRange();
    },

    updateFrom(event: InputEvent) {
      this.from = (event.target as HTMLInputElement).value;
    },

    updateTo(event: InputEvent) {
      this.to = (event.target as HTMLInputElement).value;
    },

    syncDateRange() {
      if (!hasValue(this.from) || !hasValue(this.to) || this.isInvalidEndDate) {
        this.setDateRange();
        return;
      }

      const from = DateTime.fromSQL(this.from as string).setZone("UTC");
      const to = DateTime.fromSQL(this.to as string)
        .setZone("UTC")
        .plus({ days: 1 });

      this.setDateRange({ from, to });
    }
  },

  components: {
    EmployeeShift
  }
});
