





























































import Vue from "vue";
import AppliedFilters from "./../AppliedFilters.vue";
import RecordListing from "../../RecordListing/RecordListing.vue";
import AddFilter from "../AddFilter.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { QueryParams, Filter } from "../../../types";
import { merge, isNil } from "lodash";
import {
  FILTER_STATUS,
  STATUS_TODO,
  FILTER_ROLE,
  TASK_TYPES
} from "../../../constants";
import { getFilterParam } from "../../../utils";
import { DateTime } from "luxon";
export default Vue.extend({
  props: {
    queryParams: {
      type: Object,
      required: false,
      default: (): QueryParams => ({})
    }
  },

  created() {
    this.loadFilterDependencies();
  },

  data() {
    return {
      STATUS_TODO
    };
  },

  computed: {
    ...mapState("listing", ["filters"]),

    params(): QueryParams {
      return {
        ...this.queryParams,
        ...getFilterParam(this.filters, FILTER_STATUS, "status"),
        ...getFilterParam(this.filters, FILTER_ROLE, "roles")
      };
    }
  },

  methods: {
    ...mapActions(["loadRoles"]),

    loadFilterDependencies(): Promise<void[]> {
      return Promise.all([this.loadRoles()]);
    },

    dueOn(date: string | undefined): string | null {
      if (isNil(date)) {
        return null;
      }

      return DateTime.fromSQL(date).toLocaleString(DateTime.DATE_MED);
    }
  },

  components: {
    RecordListing,
    AppliedFilters,
    AddFilter
  }
});
