import { EmployeeListingState, RootState } from "../../../types";

import { ActionTree } from "vuex";
import axios from "axios";

export default {
  async loadQuestions({ commit }) {
    try {
      const params = {
        types: ["profile", "question"],
        unique_responses: true
      };

      const {
        data: { data: questions }
      } = await axios.get("/api/messages", { params });

      commit("setQuestions", questions);
    } catch (error) {
      commit(
        "listing/setError",
        "An unexpected error occurred. Please try again later.",
        { root: true }
      );
    }
  }
} as ActionTree<EmployeeListingState, RootState>;
