






























import Vue from "vue";
import { NO_RESPONSE } from "../../../constants";
import { mapState } from "vuex";
import { isNil } from "lodash";
import { SelectOption, Message, Filter } from "../../../types";
import AppliedFilter from "../../RecordListing/AppliedFilter.vue";

export default Vue.extend({
  props: {
    filter: {
      required: true,
      type: Object as () => Filter
    }
  },

  computed: {
    ...mapState("employees", ["questions"]),

    choices(): SelectOption[] {
      if (isNil(this.filter.choices)) {
        throw new Error(
          `The [response] with cid [${this.filter.cid}] filter is missing a choice.`
        );
      }

      return this.filter.choices.map(choice => {
        switch (String(choice).toLowerCase()) {
          case "yes":
            return { value: "yes", display: "Yes" };
          case "no":
            return { value: "no", display: "No" };
          case NO_RESPONSE:
            return { value: NO_RESPONSE, display: "Unanswered" };
          default:
            throw new Error(
              `Could not translate raw choice [${choice}] to a SelectOption`
            );
        }
      });
    },

    question(): Message {
      const question = this.questions.find(
        (q: Message) => q.id === this.filter.value
      );

      if (question === undefined) {
        throw new Error(
          `A message with id [${this.filter.value}] could not be found in the store.`
        );
      }

      return question;
    }
  },

  components: {
    AppliedFilter
  }
});
