





















































import Vue from "vue";
import AppliedFilters from "./AppliedFilters.vue";
import RecordListing from "../RecordListing/RecordListing.vue";
import AddFilter from "./AddFilter.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { QueryParams, Filter, Employee, User } from "../../types";
import { merge } from "lodash";
import { FILTER_STATUS, FILTER_ROLE } from "../../constants";
import { getFilterParam } from "../../utils";

export default Vue.extend({
  props: {
    queryParams: {
      type: Object,
      required: false,
      default: (): QueryParams => ({})
    }
  },

  created() {
    this.loadFilterDependencies();
  },

  computed: {
    ...mapState("listing", ["filters"]),

    params(): QueryParams {
      return {
        ...this.queryParams,
        ...getFilterParam(this.filters, FILTER_ROLE, "roles")
      };
    }
  },

  methods: {
    ...mapActions(["loadRoles"]),

    fullName(user: User): string {
      return `${user.first_name} ${user.last_name}`;
    },

    loadFilterDependencies(): Promise<void[]> {
      return Promise.all([this.loadRoles()]);
    }
  },

  components: {
    RecordListing,
    AppliedFilters,
    AddFilter
  }
});
