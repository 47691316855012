














































import Vue from "vue";
import ReasonSelect from "./ReasonSelect.vue";
import ReasonCodeSelect from "./ReasonCodeSelect.vue";
import DateRangeForm from "./DateRangeForm.vue";
import CovidScreeningForm from "./CovidScreeningForm.vue";
import CovidTestedForm from "./CovidTestedForm.vue";
import CovidExposureForm from "./CovidExposureForm.vue";
import SelfQuarantineForm from "./SelfQuarantineForm.vue";
import StandardForm from "./StandardForm.vue";
import StatusToggle from "../../StatusToggle.vue";
import ReasonCode from "./ReasonCode.vue";
import FreeFormQuestions from "./FreeFormQuestions.vue";
import { mapMutations, mapState, mapGetters } from "vuex";
import { DateTime } from "luxon";

let $unwatch: any;

export default Vue.extend({
  created() {
    $unwatch = this.$store.watch(
      state => JSON.parse(JSON.stringify(state.callOut)),
      () => this.$nextTick(() => this.validate())
    );

    this.validate();
  },

  beforeDestroy() {
    $unwatch();
  },

  data() {
    return {
      canSubmit: false
    };
  },

  computed: {
    ...mapState(["employee"]),
    ...mapGetters("callOut", [
      "hasReason",
      "reason",
      "reasonCode",
      "hasDateRange"
    ]),
    ...mapGetters("issue", ["isEditing"]),
    ...mapState("issue", ["status"]),
    ...mapState("callOut", ["startsAt", "endsAt", "reasonCodes", "createdAt"]),

    /**
     * Questions can only be modified for new call outs and those created today.
     *
     * @link https://trello.com/c/asnRJedh
     */
    questionsAreNowReadonly(): boolean {
      if (!this.createdAt) {
        // the call out is being created
        return false;
      }

      const tz = window.VILLAGE.timezone;
      const now = DateTime.local().setZone(tz);
      const createdAt = DateTime.fromISO(this.createdAt).setZone(tz);

      return !now.hasSame(createdAt, "day");
    }
  },

  methods: {
    ...mapMutations("issue", ["toggleStatus"]),

    validate(): void {
      if (!this.hasReason || !this.hasDateRange) {
        this.canSubmit = false;
        return;
      }

      if (this.$el.querySelectorAll(".field.error").length) {
        this.canSubmit = false;
        return;
      }

      const requiredInputs = [
        ...this.$el.querySelectorAll('input[required="required"]')
      ];

      if (
        requiredInputs.some(el => (el as HTMLInputElement).value.length === 0)
      ) {
        this.canSubmit = false;
        return;
      }

      this.canSubmit = true;
    }
  },

  components: {
    StandardForm,
    DateRangeForm,
    CovidScreeningForm,
    CovidTestedForm,
    CovidExposureForm,
    SelfQuarantineForm,
    ReasonSelect,
    FreeFormQuestions,
    ReasonCode,
    ReasonCodeSelect,
    StatusToggle
  }
});
