
































import Vue from "vue";
import { range } from "lodash";
import pluralize from "pluralize";
import { mapMutations } from "vuex";

/**
 * A working, improved version of the pagination algorith described at the link below.
 *
 * @link https://gist.github.com/kottenator/9d936eb3e4e3c3e02598#gistcomment-2972387
 */
function paginate(currentPage: number, totalPages: number, delta = 2) {
  // creates array with base 1 index
  return range(1, totalPages + 1).reduce((pages: any[], page: number) => {
    // allow adding of first and last pages
    if (page === 1 || page === totalPages) {
      return [...pages, page];
    }

    // if within delta range add page
    if (page - delta < currentPage && page + delta > currentPage) {
      return [...pages, page];
    }

    // otherwise add 'gap if gap was not the last item added.
    if (pages[pages.length - 1] !== "...") {
      return [...pages, "..."];
    }

    return pages;
  }, []);
}

export default Vue.extend({
  props: {
    last: {
      type: Number,
      required: true
    },
    current: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    what: {
      type: String,
      required: false,
      default: (): string => "record"
    }
  },
  computed: {
    pages(): (number | string)[] {
      return paginate(this.current, this.last, 3);
    }
  },
  methods: {
    ...mapMutations("listing", ["setPage"]),

    pluralize,

    replaceTokenInURL(url: string, page: number, token = "{page}"): string {
      return url.replace(token, String(page));
    }
  }
});
