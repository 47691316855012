import {
  EmployeeListingState,
  Message,
  RootState,
  Filter,
  QueryParams
} from "../../../types";

import { GetterTree } from "vuex";
import {
  FILTER_RESPONSE,
  FILTER_STORE,
  FILTER_DEPARTMENT,
  FILTER_GROUP,
  FILTER_SCHEDULED,
  FILTER_CLOSE_CONTACT
} from "../../../constants";
import { getFilterParam } from "../../../utils";

export default {
  unusedQuestions: (
    state: EmployeeListingState,
    getters,
    rootState
  ): Message[] =>
    state.questions.filter(
      (question: Message) =>
        !rootState.listing.filters.some((f: Filter) => f.value === question.id)
    ),
  additionalQueryParams: (state, getters, rootState): QueryParams => {
    const params: QueryParams = {
      responses: JSON.parse(
        JSON.stringify(
          rootState.listing.filters
            .filter((f: Filter) => f.type === FILTER_RESPONSE)
            .map((filter: Filter) => ({
              message_id: filter.value,
              search: filter.choices,
              match: filter.match
            }))
        )
      ),
      stores: rootState.listing.filters.find(
        (f: Filter) => f.type === FILTER_STORE
      )?.choices,
      departments: rootState.listing.filters.find(
        (f: Filter) => f.type === FILTER_DEPARTMENT
      )?.choices,
      groups: rootState.listing.filters.find(
        (f: Filter) => f.type === FILTER_GROUP
      )?.choices
    };

    return {
      ...params,
      ...getFilterParam(
        rootState.listing.filters,
        FILTER_SCHEDULED,
        "scheduled"
      ),
      ...getFilterParam(
        rootState.listing.filters,
        FILTER_CLOSE_CONTACT,
        "close_contact"
      )
    };
  }
} as GetterTree<EmployeeListingState, RootState>;
