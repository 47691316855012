




















import Vue from "vue";
import AppliedFilter from "../../RecordListing/AppliedFilter.vue";
import {
  Filter,
  SelectOption,
  Department,
  EmployeeGroup
} from "../../../types";
import { mapState } from "vuex";
import { isNil } from "lodash";

export default Vue.extend({
  props: {
    filter: {
      required: true,
      type: Object as () => Filter
    }
  },
  computed: {
    ...mapState(["groups"]),

    choices(): SelectOption[] {
      if (isNil(this.filter.choices)) {
        throw new Error("The [group] filter is missing a choice.");
      }

      return this.filter.choices.map(
        (id): SelectOption => ({
          value: id,
          display: this.groups.find(
            (group: EmployeeGroup): boolean => Number(group.id) === Number(id)
          ).name
        })
      );
    }
  },
  components: {
    AppliedFilter
  }
});
