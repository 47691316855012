






























































































import Vue from "vue";
import { CALLOUT_REASON_SELF_QUARANTINE } from "../../../constants";
import { mapState, mapMutations } from "vuex";

/**
 * When the call out reason is that the employee is in self-quarantine, a few
 * follow-up questions are needed.
 */
export default Vue.extend({
  computed: {
    ...mapState("callOut", [
      "reason",
      "isImmunocompromised",
      "hasDoctorsNote",
      "caresForHighRiskPerson"
    ]),

    isVisible(): boolean {
      return this.reason === CALLOUT_REASON_SELF_QUARANTINE;
    }
  },

  methods: {
    ...mapMutations("callOut", [
      "setIsImmunocompromised",
      "setCaresForHighRiskPerson",
      "setHasDoctorsNote"
    ])
  }
});
