




import Vue from "vue";
import TypeLayout from "./Layouts/TypeLayout.vue";
import DefaultLayout from "./Layouts/DefaultLayout.vue";
import { TASK_TYPES, FILTER_REASON_CODE, FILTER_DUE_ON } from "../../constants";
import { mapActions, mapState } from "vuex";
import { getFilterParam } from "../../utils";
import { QueryParams } from "../../types";

export default Vue.extend({
  props: {
    queryParams: {
      type: Object,
      required: false,
      default: (): QueryParams => ({})
    }
  },

  created() {
    this.loadFilterDependencies();
  },

  computed: {
    ...mapState("listing", ["filters"]),

    layoutComponent(): string {
      if (TASK_TYPES.includes(this.queryParams.type)) {
        return `type-layout`;
      }

      return "default-layout";
    },

    params(): QueryParams {
      return {
        ...this.queryParams,
        ...getFilterParam(this.filters, FILTER_REASON_CODE, "reason_code"),
        ...getFilterParam(this.filters, FILTER_DUE_ON, "due_on")
      };
    }
  },

  methods: {
    ...mapActions("callOut", ["loadReasonCodes"]),

    loadFilterDependencies(): Promise<void[]> {
      return Promise.all([this.loadReasonCodes()]);
    }
  },

  components: {
    DefaultLayout,
    TypeLayout
  }
});
