import Vue from "vue";
import StatusToggle from "../../components/StatusToggle.vue";
import RoleSelect from "../../components/RoleSelect.vue";
import { Role, TaskStatus } from "../../types";
import { STATUS_TODO, STATUS_COMPLETE } from "../../constants";

export const PageComponent = Vue.extend({
  props: {
    initialRole: {
      required: false,
      type: Number
    },
    initialStatus: {
      required: true,
      type: String as () => TaskStatus
    }
  },
  data() {
    return {
      role: this.initialRole,
      status: this.initialStatus
    };
  },
  methods: {
    updateRole(role: Role) {
      this.role = role.id;
    },
    toggleStatus() {
      this.status = this.status === STATUS_TODO ? STATUS_COMPLETE : STATUS_TODO;
    }
  },
  components: {
    RoleSelect,
    StatusToggle
  }
  //@ts-ignore
}).extendOptions;
