






import Vue from "vue";
import { FilterChoice } from "../../types";
import { FILTER_STATUS, FILTER_ROLE } from "../../constants";
import { mapGetters } from "vuex";
import RoleFilter from "../CommonFilters/Available/RoleFilter.vue";
import AddFilter from "../RecordListing/AddFilter.vue";

export default Vue.extend({
  computed: {
    ...mapGetters("listing", ["hasFilter"]),

    choices(): FilterChoice[] {
      const options: FilterChoice[] = [];

      if (!this.hasFilter(FILTER_ROLE)) {
        options.push({
          value: FILTER_ROLE,
          display: "Role"
        });
      }

      return options;
    }
  },

  components: {
    RoleFilter,

    AddFilter
  }
});
