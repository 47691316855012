




















import Vue from "vue";
import AppliedFilter from "../../RecordListing/AppliedFilter.vue";
import { Filter, SelectOption, Department } from "../../../types";
import { mapState } from "vuex";
import { isNil } from "lodash";

export default Vue.extend({
  props: {
    filter: {
      required: true,
      type: Object as () => Filter
    }
  },
  computed: {
    ...mapState(["departments"]),

    choices(): SelectOption[] {
      if (isNil(this.filter.choices)) {
        throw new Error("The [department] filter is missing a choice.");
      }

      return this.filter.choices.map(
        (id): SelectOption => ({
          value: id,
          display: (() => {
            const department = this.departments.find(
              (department: Department): boolean =>
                Number(department.id) === Number(id)
            );

            return `${department.name || "(No Name)"} - #${department.number}`;
          })()
        })
      );
    }
  },
  components: {
    AppliedFilter
  }
});
