

























import Vue from "vue";
import { SelectOption, EmployeeGroup } from "../../../types";
import { mapState, mapActions } from "vuex";
import { FILTER_GROUP } from "../../../constants";

export default Vue.extend({
  data() {
    return {
      selected: []
    };
  },

  computed: {
    ...mapState(["groups"]),

    choices(): SelectOption[] {
      return this.groups.map(
        (group: EmployeeGroup): SelectOption => ({
          display: group.name,
          value: group.id
        })
      );
    }
  },

  methods: {
    ...mapActions("listing", ["addFilter"]),

    add() {
      this.addFilter({
        type: FILTER_GROUP,
        choices: this.selected.map((c: SelectOption) => c.value)
      });
    }
  }
});
