





























import Vue from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";

export default Vue.extend({
  props: {
    fieldName: {
      type: String,
      required: false,
      default: (): string => "selected"
    }
  },

  computed: {
    ...mapState("listing", ["selectingAll", "selected"]),
    ...mapGetters("listing", ["hasSelection", "selectedCount", "listing"])
  },
  methods: {
    ...mapMutations("listing", ["deselectAll", "selectAll", "toggleSelection"])
  }
});
