import {
  Department,
  RootState,
  Store,
  EmployeeGroup,
  Role,
  Employee
} from "../types";

import { MutationTree } from "vuex";

export default {
  setDepartments(state, departments: Department[] = []) {
    state.departments = departments;
  },

  setGroups(state, groups: EmployeeGroup[] = []) {
    state.groups = groups;
  },

  setStores(state, stores: Store[] = []) {
    state.stores = stores;
  },

  setRoles(state, roles: Role[] = []) {
    state.roles = roles;
  },
  setEmployee(state, employee: Employee) {
    state.employee = employee;
  },

  setHasKronosMobile(state, hasKronos: boolean) {
    if (state.employee === null) {
      throw new Error("No employee exists in the store.");
    }

    state.employee.has_kronos_mobile = hasKronos;
  }
} as MutationTree<RootState>;
