import EmployeeListing from "../../components/EmployeeListing/EmployeeListing.vue";
import Vue from "vue";

export const PageComponent = Vue.extend({
  components: {
    EmployeeListing
  }

  //@ts-ignore
}).extendOptions;
