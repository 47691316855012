











import Vue from "vue";
import RoleFilter from "../CommonFilters/Applied/RoleFilter.vue";
import { Filter } from "../../types";

export default Vue.extend({
  props: {
    filters: {
      required: true,
      type: Array as () => Filter[]
    }
  },

  components: {
    RoleFilter
  }
});
