

















import Vue from "vue";

export default Vue.extend({
  props: {
    subset: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    subsetLabel: {
      type: String,
      required: true
    },
    totalLabel: {
      type: String,
      required: true
    }
  },

  computed: {
    percentage(): number {
      if (this.total === 0) {
        return 0;
      }

      return Math.round((this.subset / this.total) * 100);
    }
  }
});
