







import Vue from "vue";
import { EmployeeShift } from "../../../types";
import { DateTime } from "luxon";

export default Vue.extend({
  props: {
    shift: {
      required: true,
      type: Object as () => EmployeeShift
    }
  },

  computed: {
    labelText(): string {
      const from = DateTime.fromISO(this.shift.starts_at);
      const to = DateTime.fromISO(this.shift.ends_at);

      if (from.hasSame(to, "day")) {
        return `${from.toFormat("cccc, LLLL d, Y, h:mm a")} - ${to.toFormat(
          "h:mm a"
        )}`;
      } else {
        const format = "cccc, LLLL d, Y 'at' h:mm a";

        return `${from.toFormat(format)} to ${to.toFormat(format)}`;
      }
    }
  }
});
