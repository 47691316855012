



























import Vue from "vue";
import { mapGetters } from "vuex";
import pluralize from "pluralize";

export default Vue.extend({
  props: {
    error: {
      type: String,
      required: false,
      default: (): string => ""
    }
  },

  data() {
    return {
      name: ""
    };
  },

  computed: {
    ...mapGetters("listing", ["hasSelection", "selectedCount"]),

    hasError(): boolean {
      return this.error.trim().length > 0;
    },

    isValid(): boolean {
      return this.name.trim().length > 0 && this.hasSelection;
    }
  },

  methods: {
    pluralize
  }
});
