import Vue from "vue";
import { BugReporter, BugsnagReporter, ConsoleReporter } from "./BugReporter";

import Container from "./ServiceContainer";

// The container instance within which services will be registered
const container = new Container();

container.factory(
  "bugreporter",
  (): BugReporter => {
    if (process.env.VUE_APP_BUGSNAG_CLIENT_API_KEY) {
      return new BugsnagReporter(
        process.env.VUE_APP_BUGSNAG_CLIENT_API_KEY,
        process.env.VUE_APP_VERSION
      );
    }

    return new ConsoleReporter();
  }
);

container.factory("bus", (): Vue => new Vue());

export default container;
