import axios from "axios";
import { isNil } from "lodash";
import pluralize from "pluralize";
import Vue from "vue";
import { mapGetters } from "vuex";

import EmployeeListing from "../../components/EmployeeListing/EmployeeListing.vue";
import LengthRestrictedInput from "../../components/LengthRestrictedInput.vue";
import {
  MESSAGE_NOTICE,
  MESSAGE_PROFILE,
  MESSAGE_QUESTION
} from "../../constants";

interface MessageTemplate {
  id: number;
  short_description: string;
  content: string;
}

export const PageComponent = Vue.extend({
  async created() {
    const {
      data: { data: templates }
    } = await axios.get("/api/message-templates");

    this.loading = false;

    if (templates.length) {
      this.templates = templates;
      this.mode = "template";
    }
  },
  data() {
    return {
      mode: "custom",
      loading: true,
      type: MESSAGE_NOTICE,
      short_description: "",
      content: "",
      spanishContent: "",
      template: null as MessageTemplate | null,
      templates: [] as MessageTemplate[]
    };
  },

  computed: {
    ...mapGetters("listing", ["hasSelection", "selectedCount"]),
    isValid(): boolean {
      if (!this.hasSelection && !this.isCreatingProfileQuestion) {
        return false;
      }

      if (this.short_description.trim().length <= 0) {
        return false;
      }

      if (this.content.trim().length <= 0 && isNil(this.template)) {
        return false;
      }

      return true;
    },
    isCreatingProfileQuestion(): boolean {
      return this.type === MESSAGE_PROFILE;
    },
    isCreatingQuestion(): boolean {
      return [MESSAGE_PROFILE, MESSAGE_QUESTION].includes(this.type);
    }
  },

  methods: {
    /**
     * Switch to the 'custom' message mode from the 'template' mode. Autofocus
     * on the content field.
     */
    customize() {
      this.mode = "custom";

      this.$nextTick(() =>
        (this.$el.querySelector('input[name="content"]') as HTMLElement).focus()
      );
    },

    confirmDelivery(event: Event) {
      if (
        !confirm(
          `You are about to send this text message to ${pluralize(
            "employee",
            this.selectedCount,
            true
          )}. Are you sure you want to do this?`
        )
      ) {
        event.preventDefault();
        event.stopPropagation();

        return false;
      }

      return true;
    },

    pluralize
  },

  components: {
    EmployeeListing,
    LengthRestrictedInput
  }
  //@ts-ignore
}).extendOptions;
