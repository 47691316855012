




import Vue from "vue";
import { STATUS_OPEN } from "../constants";
import { StatusRecord } from "../types";

export default Vue.extend({
  props: {
    record: {
      required: true,
      type: Object as () => StatusRecord
    },
    openStatus: {
      required: false,
      type: String,
      default: STATUS_OPEN
    }
  },

  computed: {
    bgColorClass(): string {
      return this.record.status === this.openStatus
        ? "bg-green-500"
        : "bg-red-500";
    }
  }
});
