




import Vue from "vue";

export default Vue.extend({
  props: {
    content: {
      type: String,
      required: true
    },
    maxLength: {
      type: Number,
      required: false,
      default: (): number => 10
    }
  },

  computed: {
    displayContent(): string {
      if (this.maxLength !== -1 && this.content.length > this.maxLength) {
        return this.content.substring(0, this.maxLength + 1) + "...";
      }

      return this.content;
    }
  }
});
