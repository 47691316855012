import axios from "axios";
import qs from "qs";
import { ActionTree } from "vuex";

import container from "../../../services";
import {
  AddFilterMutation,
  QueryParams,
  RecordListingState,
  RootState
} from "../../../types";

export default {
  async loadRecords(
    { commit, state },
    {
      endpoint,
      queryParams = {}
    }: { endpoint: string; queryParams: QueryParams }
  ) {
    commit("setLoading");
    commit("setError");

    const params: QueryParams = {
      ...queryParams,
      page: state.page,
      search: state.search
    };

    try {
      const {
        data: { data, meta }
      } = await axios.get(endpoint, {
        params,
        paramsSerializer: params => qs.stringify(params, { encode: false })
      });

      commit("setRecords", data);
      commit("setPagination", {
        current: meta.current_page,
        last: meta.last_page,
        recordsTotal: meta.total,
        recordsPer: meta.per_page
      });
    } catch (error) {
      const errors = (Object.values(
        error?.response?.data?.errors || {}
      ) as string[][]).reduce((acc: string[], errors: string[]): string[] => {
        return acc.concat(errors);
      }, []);

      commit(
        "setError",
        errors?.[0] || "An unexpected error occurred. Please try again later."
      );
    } finally {
      commit("setLoading", false);
    }
  },

  async reset({ commit }) {
    commit("setPage");
    commit("deselectAll");
    container.resolve<Vue>("bus").$emit("listing:reset");
  },

  addFilter({ commit, dispatch }, filter: AddFilterMutation) {
    dispatch("reset");
    commit("addFilter", filter);
  },

  removeFilter({ commit, dispatch }, cid: string) {
    dispatch("reset");
    commit("removeFilter", cid);
  }
} as ActionTree<RecordListingState, RootState>;
