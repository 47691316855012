






import Vue from "vue";
import { FilterChoice } from "../../types";
import {
  FILTER_STATUS,
  FILTER_ROLE,
  FILTER_REASON_CODE,
  FILTER_DUE_ON
} from "../../constants";
import { mapGetters, mapState } from "vuex";
import StatusFilter from "./AvailableFilters/StatusFilter.vue";
import RoleFilter from "../CommonFilters/Available/RoleFilter.vue";
import AddFilter from "../RecordListing/AddFilter.vue";
import ReasonCodeFilter from "../CommonFilters/Available/ReasonCodeFilter.vue";
import DueOnFilter from "./AvailableFilters/DueOnFilter.vue";

export default Vue.extend({
  computed: {
    ...mapGetters("listing", ["hasFilter"]),
    ...mapState("callOut", ["reasonCodes"]),

    choices(): FilterChoice[] {
      const options: FilterChoice[] = [];

      if (!this.hasFilter(FILTER_STATUS)) {
        options.push({
          value: FILTER_STATUS,
          display: "Status"
        });
      }

      if (!this.hasFilter(FILTER_ROLE)) {
        options.push({
          value: FILTER_ROLE,
          display: "Role"
        });
      }

      if (this.reasonCodes.length && !this.hasFilter(FILTER_REASON_CODE)) {
        options.push({
          value: FILTER_REASON_CODE,
          display: "Reason Code"
        });
      }

      if (!this.hasFilter(FILTER_DUE_ON)) {
        options.push({
          value: FILTER_DUE_ON,
          display: "Due On"
        });
      }

      return options;
    }
  },

  components: {
    StatusFilter,
    RoleFilter,
    ReasonCodeFilter,
    DueOnFilter,

    AddFilter
  }
});
