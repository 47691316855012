









import Vue from "vue";
import { DateTime, LocaleOptions, DateTimeFormatOptions } from "luxon";

export default Vue.extend({
  props: {
    timestamp: {
      required: false,
      type: String
    },
    fallback: {
      type: String,
      required: false,
      default: (): string => "Not Available"
    },
    format: {
      type: String,
      required: false,
      default: (): string => "LLL d, y, h:mm a"
    }
  },

  computed: {
    raw(): DateTime | null {
      if (!this.timestamp) {
        return null;
      }

      return DateTime.fromISO(this.timestamp);
    },

    machine(): string | null {
      if (this.raw === null) {
        return null;
      }

      return this.raw.toISO();
    },

    formattedFull(): string | null {
      if (this.raw === null) {
        return null;
      }

      return this.raw.toLocaleString(DateTime.DATETIME_FULL);
    },

    formatted(): string | null {
      if (this.raw === null) {
        return null;
      }

      return this.raw.toFormat(this.format as string);
    }
  }
});
