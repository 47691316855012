














import Vue from "vue";
import qs from "qs";
import { QueryParams } from "../types";
import { merge } from "lodash";

const SORT_ASC: "asc" = "asc";
const SORT_DESC: "desc" = "desc";

type SortDirection = typeof SORT_ASC | typeof SORT_DESC;

export default Vue.extend({
  props: {
    defaultDirection: {
      required: false,
      type: String as () => SortDirection,
      default: (): SortDirection => SORT_ASC
    },
    param: {
      required: true,
      type: String
    }
  },

  computed: {
    isAscending(): boolean {
      return this.direction === SORT_ASC;
    },

    isDescending(): boolean {
      return !this.isAscending;
    },

    direction(): SortDirection | null {
      if (!this.isSorting) {
        return null;
      }

      const direction = this.queryString?.sort?.[this.param];

      if ([SORT_ASC, SORT_DESC].includes(direction)) {
        return direction;
      }

      return null;
    },

    newDirection(): SortDirection {
      if (!this.isSorting) {
        return this.defaultDirection;
      }

      return this.direction === SORT_ASC ? SORT_DESC : SORT_ASC;
    },

    url(): string {
      const queryString = {
        ...this.queryString,
        sort: { [this.param]: this.newDirection }
      };

      return (
        window.location.pathname +
        "?" +
        qs.stringify(queryString, { encode: false })
      );
    },

    queryString(): QueryParams {
      return qs.parse(window.location.search.replace(/^\?/, ""));
    },

    isSorting(): boolean {
      return Object.keys(this.queryString?.sort || {}).includes(this.param);
    },

    classes(): string[] {
      const classes: string[] = [];

      if (this.direction) {
        classes.push(this.direction);
      }

      return classes;
    }
  }
});
